import Bom from '@/class/Bom';

class BomAlert {
  public getBackgroundColor(): string {
    return this.backgroundColor;
  }

  public setBackgroundColor(value: string) {
    this.backgroundColor = value;
  }

  public getTextColor(): string {
    return this.textColor;
  }

  public setTextColor(value: string) {
    this.textColor = value;
  }

  public setMessage(message: string) {
    this.message = message;
  }

  public getMessage(): string {
    return this.message;
  }

  public getBom() {
    return this.bom;
  }

  private readonly bom: Bom;

  private backgroundColor = '';

  private textColor = '';

  private message = '';

  constructor(bom: Bom) {
    this.bom = bom;
  }
}

export default BomAlert;
