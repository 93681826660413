import Serializable from '@/helper/SerializationHelper';

class AssociateAffaire extends Serializable {
  public ClientCode = '';

  public Job = 0;

  public Qty = 0;

  constructor(client: string, job: number, qty: number) {
    super();
    this.ClientCode = client;
    this.Job = job;
    this.Qty = qty;
  }
}

export default AssociateAffaire;
