import UserEntity from '@/class/UserEntity';
import UserStore from '@/store/UserStore';

class UserHelper {
  public static getUserById(userId: string) : UserEntity | undefined {
    const userStore = UserStore();
    return (userStore.users as Array<UserEntity>).find((user) => user.User === userId);
  }
}

export default UserHelper;
