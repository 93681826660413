import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "h-full bg-gray-100 p-5",
  id: "bg"
}
const _hoisted_2 = { class: "flex w-full h-full justify-center items-center flex-col" }
const _hoisted_3 = { class: "w-full max-w-xl rounded-xl overflow-hidden" }
const _hoisted_4 = { class: "flex w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_h1 = _resolveComponent("gui-h1")!
  const _component_gui_input_text = _resolveComponent("gui-input-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_gui_h1, { class: "uppercase mt-3 uppercase mb-8 text-white font-thin text-5xl" }, {
        default: _withCtx(() => [
          _createTextVNode("Connexion")
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_gui_input_text, {
            class: "rounded-none focus:border-0 border-0 focus:ring-0 focus:outline-none border-r placeholder:font-light",
            onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.connect()), ["enter"])),
            placeholder: "Utilisateur",
            onValueChanged: _ctx.usernameChanged
          }, null, 8, ["onValueChanged"]),
          _createVNode(_component_gui_input_text, {
            type: "password",
            class: "rounded-none focus:border-0 border-0 focus:ring-0 focus:outline-none border-l placeholder:font-light",
            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.connect()), ["enter"])),
            placeholder: "Mot de passe",
            onValueChanged: _ctx.plainPasswordChanged
          }, null, 8, ["onValueChanged"])
        ]),
        _createElementVNode("button", {
          class: "w-full bg-gray-900 text-white uppercase text-xl font-thin py-4 hover:bg-black transition",
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.connect()), ["enter"])),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.connect && _ctx.connect(...args)))
        }, "connexion", 32)
      ])
    ])
  ]))
}