import { defineStore } from 'pinia';
import MainEndpoint from '@/endpoints/main';
import Notify from '@/helper/Notification';
import UserEntity from '@/class/UserEntity';
import lodash from 'lodash';

const UserStore = defineStore('user', {
  state: () => ({
    users: new Array<UserEntity>(),
  }),
  getters: {
    operators(state) {
      return state.users.filter((u) => u.Department === 'ATELI' && u.ReleaseDate === '0000-00-00').sort((a, b) => {
        if (a.Name < b.Name) { return -1; }
        if (a.Name > b.Name) { return 1; }
        return 0;
      });
    },
  },
  actions: {
    loadAllUsers() {
      return MainEndpoint.getAxiosInstance()
        .get('users')
        .then((response) => {
          const newUsers = new Array<UserEntity>();
          lodash.forEach(response.data, (c: any) => {
            const userEntityObject = new UserEntity();
            userEntityObject.fillFromJSON(c);
            newUsers.push(userEntityObject);
          });
          this.users = newUsers;
        })
        .catch((reason) => {
          console.log(reason);
          Notify.notifyFailure('Impossible de récuperer la liste des Users');
        });
    },
  },
});

export default UserStore;
