import Serializable from '@/helper/SerializationHelper';

class Reservation extends Serializable {
  public Idreservation = 0;

  public Product = '';

  public Qty = '';

  public User = '';

  public IdWarehouse = 0;

  public Attributes = '';

  public Date = '';
}

export default Reservation;
