import UserAuthManager from '@/class/UserAuthManager';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import MainEndpoint from '@/endpoints/main';
import App from './App.vue';
import router from './router';

import './assets/css/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'v-calendar/dist/style.css';
import './assets/css/inputs.scss';
import 'vue-datepicker-next/locale/fr';
import 'vue-datepicker-next/scss/index.scss';

const pinia = createPinia();

createApp(App).use(router).use(pinia)
  .mount('#app');

// This need to be called after app mount.
router.push('Login').then(() => {
  UserAuthManager.tryRestoreSession();
  MainEndpoint.loadAxiosMiddleWare();
  UserAuthManager.startKeepAliveSession();
});
