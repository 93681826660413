// Oui il y a beaucoup de eslint-disable
// Mais c'est ce qui arrive lorsqu'on a la regle no any
// Et qu'on bosse avec une api
class Serializable {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  fillFromJSON(data: any): void {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const propName in data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[propName] = data[propName];
    }
  }

  fillFromJSONString(data: string): void {
    const dataTable = JSON.parse(data);
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const propName in dataTable) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this[propName] = dataTable[propName];
    }
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
export default Serializable;
