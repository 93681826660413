import Serializable from '@/helper/SerializationHelper';

class SupplierDeliveryNotes extends Serializable {
  public idSDN = 0;

  public SupplierDN = '';

  public Date = '';

  public idorder = 0;

  public idbom = 0;

  public Qty = 0;
}

export default SupplierDeliveryNotes;
