import Serializable from '@/helper/SerializationHelper';
import DateHelper from '@/helper/DateHelper';
import Affaire from '@/class/Affaire';
import RankRouting from '@/class/RankRouting';
import CostCenter from '@/class/CostCenter';
import GanttStore from '@/store/GanttStore';
import RankClockingState from '@/enum/RankClockingState';
import BomHelper from '@/helper/BomHelper';
import lodash from 'lodash';
import BomGroupStatus from '@/enum/BomGroupStatus';

class AffaireRank extends Serializable {
  public IdJobFile = 0;

  public Parent = undefined as undefined | AffaireRank;

  public Childs = Array<AffaireRank>();

  public Postes = Array<RankRouting>();

  public Designation = '';

  public Rank = '';

  public comment = '';

  public Level = 0;

  public affaire: Affaire;

  public Coef = 0;

  public Part = '';

  public ChildsCostCenter = Array<string>();

  public delayTaskList = [] as Array<any>;

  public delayNomenclatureTaskList = [] as Array<any>;

  public delaySousTraitanceTaskList = [] as Array<any>;

  constructor(affaire: Affaire) {
    super();
    this.affaire = affaire;
  }

  fillFromJSON(data: any): void {
    super.fillFromJSON(data);
    const ranks = Array<AffaireRank>();
    this.Childs.forEach((r) => {
      const classRank = new AffaireRank(this.affaire);
      classRank.fillFromJSON(r);
      classRank.Parent = this;
      ranks.push(classRank);
      this.ChildsCostCenter = lodash.union(this.ChildsCostCenter, classRank.ChildsCostCenter);
    });
    this.Childs = ranks;
    this.Childs.sort((a, b) => {
      if (parseInt(a.Rank.substring(a.Rank.length - 3), 10)
        > parseInt(b.Rank.substring(b.Rank.length - 3), 10)) {
        return 1;
      }
      return -1;
    });

    const postes = Array<RankRouting>();
    this.Postes.forEach((r: any) => {
      const classRouting = new RankRouting();
      classRouting.fillFromJSON(r);
      postes.push(classRouting);

      if (classRouting.CostCenterObject?.Group !== undefined) {
        if (!this.ChildsCostCenter.includes(classRouting.CostCenterObject.Group)) {
          this.ChildsCostCenter.push(classRouting.CostCenterObject.Group);
        }
      }

      classRouting.Rank = this;
      classRouting.Affaire = this.affaire;
      if ((r.startDate === undefined || r.endDate === undefined
        || r.weeksHoursDistributor === undefined) && r.groupedId === undefined) {
        classRouting.recalculateWeeksHoursDistributor(classRouting.getManuTimeWithCoef());
        classRouting.persistDate();
      }
    });
    this.Postes = postes;
  }

  public getMinDateInParent(): Date | undefined {
    let testDate: Date | undefined;
    if (this.Parent === undefined) return undefined;
    this.Parent.getRoutingsToShow().forEach((parentRouting) => {
      if (testDate === undefined) {
        testDate = parentRouting.getStartDate();
      } else {
        DateHelper.min(testDate, parentRouting.getStartDate());
      }
    });
    return testDate === undefined ? undefined : new Date(testDate);
  }

  public getMaxDate(): Date | undefined {
    let testDate: Date | undefined;
    this.getRoutingsToShow().forEach((routing) => {
      if (testDate === undefined) {
        testDate = routing.endDate;
      } else {
        testDate = DateHelper.max(testDate, routing.endDate);
      }
    });
    return testDate === undefined ? undefined : new Date(testDate);
  }

  public getMinDate(): Date | undefined {
    let testDate: Date | undefined;
    this.getRoutingsToShow().forEach((routing) => {
      if (testDate === undefined) {
        testDate = routing.startDate;
      } else {
        testDate = DateHelper.min(testDate, routing.startDate);
      }
    });
    return testDate === undefined ? undefined : new Date(testDate);
  }

  public getMaxDateInChildrens(): Date | undefined {
    let testDate: Date | undefined;
    this.Childs.forEach((rankChild) => {
      rankChild.getRoutingsToShow().forEach((routing) => {
        if (testDate === undefined) {
          testDate = routing.endDate;
        } else {
          testDate = DateHelper.max(testDate, routing.endDate);
        }
      });
    });
    return testDate === undefined ? undefined : new Date(testDate);
  }

  public getRoutingsMoveToShow() {
    return this.Postes.filter((r) => !['NOMEN', 'COMM'].includes(r.CostCenter)
      && r.CostCenterObject !== undefined
      && r.CostCenterObject.Group !== undefined
      // On enleve les grouped routings
      && !Object.keys(CostCenter.regroupedGroupId)
        .includes((r.CostCenterObject as CostCenter).Group as string));
  }

  public getRoutingsToShow(isGanttValueTask = false) {
    return lodash.filter(this.Postes, (r) => !['NOMEN', 'COMM'].includes(r.CostCenter)
      && r.CostCenterObject !== undefined
      && r.CostCenterObject.Group !== undefined
      && r.isShowSubTask(isGanttValueTask)
      // On enleve les grouped routings
      && !Object.keys(CostCenter.regroupedGroupId)
        .includes((r.CostCenterObject as CostCenter).Group as string));
  }

  // Methode qui renvoi tous les rankroutings avec les filtres du cost centers
  public getFilteredRoutings() {
    const ganttStore = GanttStore();

    // Filtre par cost center
    let tasks = this.getRoutingsToShow()
      .filter((routing) => (ganttStore.costCenterGroupFilter.length === 0 ? true
        : ganttStore.costCenterGroupFilter.includes(routing.CostCenterObject?.Group ?? '')));
    // Si il n'y a pas de filtre sur la semaine
    if (ganttStore.weeksFilter.length === 0) {
      return tasks;
    }
    let routingInRange = false;
    tasks = tasks.filter((p) => {
      ganttStore.weeksFilter.every((dateTest) => {
        const friday = DateHelper.setToFriday(new Date(dateTest));
        friday.setHours(23, 59, 59, 0);
        if (DateHelper.isDateInRange(p.startDate, dateTest, friday)) {
          routingInRange = true;
          return false;
        }
        if (DateHelper.isDateInRange(p.endDate, dateTest, friday)) {
          routingInRange = true;
          return false;
        }
        if (p.startDate >= dateTest && p.endDate <= friday) {
          routingInRange = true;
          return false;
        }

        if (p.startDate <= dateTest && p.endDate >= friday) {
          routingInRange = true;
          return false;
        }
        return true;
      });

      return routingInRange;
    });
    return tasks;
  }

  getStateClocking(): RankClockingState {
    const routingRegrouped: any[] = [];
    const statusList: Array<RankClockingState> = [];
    let finishedChildren = true;

    this.Childs.forEach((rankChild) => {
      if (!(rankChild.getStateClocking() === RankClockingState.FINISHED)) {
        finishedChildren = false;
      }
    });

    // get all routing regrouped
    this.affaire.regroupedRoutings.forEach((regroupedRouting) => {
      routingRegrouped.push(regroupedRouting);
    });

    this.Postes.forEach((poste) => {
      // ignore NOMEN, COMM, EXPE, STTS, STPS
      if (poste.CostCenter === 'NOMEN' || poste.CostCenter === 'COMM' || poste.CostCenter === 'EXPE' || poste.CostCenter === 'STTS' || poste.CostCenter === 'STPS') return;

      const routing = routingRegrouped.find((routingRegroup) => routingRegroup.getGroup() === poste.CostCenterObject?.Group);
      if (routing === undefined) {
        statusList.push(poste.getStatusTask());
      } else {
        statusList.push(routing.getStatusTask());
      }
    });

    if (lodash.includes(statusList, RankClockingState.IN_PROGRESS)) {
      return RankClockingState.IN_PROGRESS;
    }

    if (lodash.includes(statusList, RankClockingState.FINISHED) && finishedChildren) {
      if (!lodash.includes(statusList, RankClockingState.NOT_STARTED) && !lodash.includes(statusList, RankClockingState.STARTED)) {
        return RankClockingState.FINISHED;
      }
    }

    if (lodash.includes(statusList, RankClockingState.STARTED) || lodash.includes(statusList, RankClockingState.FINISHED)) {
      return RankClockingState.STARTED;
    }

    return RankClockingState.NOT_STARTED;
  }

  public getStateLateClocking() {
    this.delayTaskList = [];
    const routingRegrouped: any[] = [];

    this.affaire.regroupedRoutings.forEach((regroupedRouting) => {
      routingRegrouped.push(regroupedRouting);
    });

    this.Postes.forEach((poste) => {
      if (poste.CostCenter === 'NOMEN' || poste.CostCenter === 'COMM' || poste.CostCenter === 'EXPE' || poste.CostCenter === 'STTS' || poste.CostCenter === 'STPS') return;

      const routing = routingRegrouped.find((routingRegroup) => routingRegroup.getGroup() === poste.CostCenterObject?.Group);
      let posteState;
      if (routing === undefined) {
        posteState = poste.getLatStatusTask();
      } else {
        posteState = routing.getLatStatusTask();
      }

      if (posteState.length > 0) {
        this.delayTaskList.push(posteState);
      }
    });

    return this.delayTaskList;
  }

  public getStatusBom(): BomGroupStatus {
    let status = BomGroupStatus.UNDEFINED;
    let newStatus = 0;

    this.Childs.forEach((child) => {
      newStatus = child.getStatusBom();
      status = BomHelper.getStatusBom(status, newStatus);
    });

    this.Postes.forEach((poste) => {
      newStatus = poste.getStatusBom();
      status = BomHelper.getStatusBom(status, newStatus);
    });

    return status;
  }

  public getNomonclatureStatus() {
    this.delayNomenclatureTaskList = [];
    this.delaySousTraitanceTaskList = [];

    this.Postes.forEach((poste) => {
      poste.getLateStatusBom(this.getMinDate());

      if (poste.listStatusNomenclature.length > 0) {
        const doubles = this.delayNomenclatureTaskList.find((task) => task[0] === poste.IdRouting);
        if (doubles === undefined) {
          this.delayNomenclatureTaskList.push([poste.IdRouting, poste.listStatusNomenclature]);
        }
      }

      if (poste.listStatusSousTraitance.length > 0) {
        const doubles = this.delaySousTraitanceTaskList.find((task) => task[0] === poste.IdRouting);
        if (doubles === undefined) {
          this.delaySousTraitanceTaskList.push([poste.IdRouting, poste.listStatusSousTraitance]);
        }
      }
    });
  }

  public getCostCenterByIdRouting(idRouting: number): string | undefined {
    return this.findPosteByIdRouting(this, idRouting);
  }

  private findPosteByIdRouting(affaireRank: AffaireRank, idRouting: number): string | undefined {
    if (!lodash.isEmpty(affaireRank.Postes)) {
      const posteFound = lodash.find(affaireRank.Postes, (post) => post.IdRouting === idRouting);
      if (!lodash.isUndefined(posteFound?.CostCenterObject?.Group)) {
        return posteFound?.CostCenterObject?.Group;
      }
    }

    if (!lodash.isEmpty(affaireRank.Childs)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of affaireRank.Childs) {
        const costCenterFound = this.findPosteByIdRouting(child, idRouting);
        if (!lodash.isUndefined(costCenterFound)) {
          return costCenterFound;
        }
      }
    }

    return undefined;
  }

  public getRoutingById(idRouting: number) {
    let routing = this.Postes.find((p) => p.IdRouting === idRouting);
    if (routing === undefined) {
      this.Childs.forEach((child) => {
        const checkRouting = child.getRoutingById(idRouting);
        if (checkRouting !== undefined) {
          routing = checkRouting;
        }
      });
    }
    return routing;
  }
}
export default AffaireRank;
