import { defineStore } from 'pinia';
import Affaire from '@/class/Affaire';
import DateHelper from '@/helper/DateHelper';
import MainEndpoint from '@/endpoints/main';
import Notify from '@/helper/Notification';
import loadPercentageAffairesBus from '@/bus/loadPercentageAffairesBus';
import lodash from 'lodash';
import GanttStore from '@/store/GanttStore';
import GanttFilterCloseBus from '@/bus/GanttFilterCloseBus';
import Notiflix from 'notiflix';
import gantFilterInput from '@/bus/gantFilterInput';

const AffaireStore = defineStore('affaires', {
  state: () => ({
    affaires: new Array<Affaire>(),
    affairesSearch: new Array<Affaire>(),
    newAffaires: new Array<Affaire>(),
    affairesDelays: new Array<Affaire>(),
    sousTraitanceDelays: new Array<Affaire>(),
    inputFilter: new Array<any>(),
    costCenterDay: {} as { [key: string]: number },
    costCenterWeek: {} as { [key: string]: number },
    getAssociateAffaire: {} as { [key: string]: Array<Affaire> },
    getAffaireByJobId: {} as Affaire,
    loadJob: {},
    isAssociateAffaire: false,
  }),
  getters: {
    sortedAffairesByCustomerCode(): Array<Affaire> {
      const ganttStore = GanttStore();
      return this.affaires.filter((affaire) => {
        if (affaire.getFilteredGroupRoutines()) return true;

        if (lodash.size(ganttStore.weeksFilter) === 0) {
          for (let i = 0; i < ganttStore.costCenterGroupFilter.length; i += 1) {
            if (lodash.includes(affaire.ChildsCostCenter, ganttStore.costCenterGroupFilter[i])) {
              return true;
            }
          }
        }

        return affaire.isShowAffaire();
      })
        .sort((a, b) => {
          if (a.CustomerCode < b.CustomerCode) {
            return 1;
          }

          if (a.CustomerCode > b.CustomerCode) {
            return -1;
          }
          return 0;
        }) as Array<Affaire>;
    },
    sortedAffairesSearchByCustomerCode(): Array<Affaire> {
      const ganttStore = GanttStore();

      return this.affairesSearch.filter((affaire) => {
        if (affaire.getFilteredGroupRoutines()) return true;

        if (lodash.size(ganttStore.weeksFilter) === 0) {
          for (let i = 0; i < ganttStore.costCenterGroupFilter.length; i += 1) {
            if (lodash.includes(affaire.ChildsCostCenter, ganttStore.costCenterGroupFilter[i])) {
              return true;
            }
          }
        }

        return affaire.isShowAffaire();
      })
        .sort((a, b) => {
          if (a.CustomerCode < b.CustomerCode) {
            return 1;
          }
          if (a.CustomerCode > b.CustomerCode) {
            return -1;
          }
          return 0;
        }) as Array<Affaire>;
    },
    getMinDateRoutingInAllAffaire(state): undefined | Date {
      let testDate : undefined | Date;
      state.affaires.forEach((affaire) => {
        const newDate = affaire.getMinDateRouting();
        if (testDate === undefined) {
          testDate = newDate;
        } else if (newDate !== undefined) {
          testDate = DateHelper.min(testDate, newDate);
        }
      });
      return testDate;
    },
    getMaxDateRoutingInAllAffaire(state): undefined | Date {
      let testDate : undefined | Date;
      state.affaires.forEach((affaire) => {
        const newDate = affaire.getMaxDateRouting();
        if (testDate === undefined) {
          testDate = newDate;
        } else if (newDate !== undefined) {
          testDate = DateHelper.max(testDate, newDate);
        }
      });
      return testDate;
    },
  },
  actions: {
    setAffaires(data: Array<Affaire>) {
      this.affaires = data;
    },
    addAffaire(data: Affaire) {
      if (this.affaires.find((affaireCheck) => affaireCheck.Job === data.Job) === undefined) {
        this.affaires.push(data);
      }
    },
    addNewAffaire(data: Affaire) {
      if (this.newAffaires.find((affaireCheck) => affaireCheck.Job === data.Job) === undefined) {
        this.newAffaires.push(data);
      }
    },
    removeNewAffaire(affaire: Affaire) {
      this.newAffaires.splice(this.newAffaires.indexOf(affaire), 1);

      const data = {
        type: 'newAffaire',
        affaireId: affaire.Job,
        codePiece: affaire.Product,
        designation: affaire.Designation1,
        customerCode: affaire.CustomerCode,
      };

      MainEndpoint.getAxiosInstance().post(`/affaire/history/update/${affaire.Job}`, data).catch(() => {
        Notiflix.Notify.failure('Nouvelle affaire non enregistrée');
      });
    },
    addAffairesDelays(affaire: Affaire) {
      if (this.affairesDelays.find((affaireCheck) => affaireCheck.Job === affaire.Job) === undefined) {
        this.affairesDelays.push(affaire);
      }
    },
    removeAffaireDelays(affaire: Affaire) {
      this.affairesDelays.splice(this.affairesDelays.indexOf(affaire), 1);

      const data = {
        type: 'affairesDelays',
        affaireId: affaire.Job,
        codePiece: affaire.Product,
        designation: affaire.Designation1,
        customerCode: affaire.CustomerCode,
      };

      MainEndpoint.getAxiosInstance().post(`/affaire/history/update/${affaire.Job}`, data).catch(() => {
        Notiflix.Notify.failure('Affaire en retard non enregistré');
      });
    },
    addSousTraitanceDelays(affaire: Affaire) {
      if (this.sousTraitanceDelays.find((affaireCheck) => affaireCheck.Job === affaire.Job) === undefined) {
        this.sousTraitanceDelays.push(affaire);
      }
    },
    removeSousTraitanceDelays(affaire: Affaire) {
      this.sousTraitanceDelays.splice(this.sousTraitanceDelays.indexOf(affaire), 1);

      const data = {
        type: 'sousTraitanceDelays',
        affaireId: affaire.Job,
        codePiece: affaire.Product,
        designation: affaire.Designation1,
        customerCode: affaire.CustomerCode,
        // OrderCodeSupplier: affaire.
        // supplier: affaire.
      };

      MainEndpoint.getAxiosInstance().post(`/affaire/history/update/${affaire.Job}`, data).catch(() => {
        Notiflix.Notify.failure('Affaire en retard non enregistré');
      });
    },
    filter() {
      this.affairesSearch = this.affaires.filter((affaire) => this.search(affaire, this.inputFilter));
    },
    getIsLoad(affaireId: number) {
      return this.affaires.find((affaireSearch) => affaireSearch.Job === affaireId) !== undefined;
    },
    multiFilter(affairesFilter: Array<number>) {
      this.resetFilter();
      this.affairesSearch = this.affaires.filter((affaire) => affairesFilter.includes(affaire.Job));
      this.inputFilter.push(['inputAffaire', affairesFilter]);
      this.isAssociateAffaire = true;
      gantFilterInput.trigger('inputAffaire', affairesFilter.join(', '));
    },
    resetFilter() {
      this.affairesSearch = [];
      this.inputFilter = [];
      this.isAssociateAffaire = false;
      GanttFilterCloseBus.trigger('clearFilter', null);
    },
    saveFilter(tab: Array<any>) {
      this.inputFilter = tab;
      this.isAssociateAffaire = false;

      this.filter();
    },
    getFilter() {
      return this.inputFilter;
    },
    getIsAssociate() {
      return this.isAssociateAffaire;
    },
    removeAffaireFromIndex(index: number) {
      this.affaires.splice(index, 1);
    },
    removeAllAffaire() {
      this.affaires = [];
    },
    loadJob(jobId: string, clb: () => void | undefined) {
      MainEndpoint.getAxiosInstance().get(`affaire/${jobId}`).then((response) => {
        const affaire = new Affaire();
        affaire.fillFromJSON(response.data);

        affaire.setNomenclatureStatus();

        if (affaire.isNewAffaire) {
          this.addNewAffaire(affaire);
        } else if (affaire.IsSousTraitanceOverDelays()) {
          this.addSousTraitanceDelays(affaire);
        } else if (affaire.IsOverDelays()) {
          this.addAffairesDelays(affaire);
        }

        if (affaire.ChildsCostCenter.length > 0) {
          this.addAffaire(affaire);
        }
      }).catch((e) => {
        console.log(e);
        Notify.notifyFailure(`Impossible de récuperer le job ${jobId}`);
      })
        .finally(() => { if (clb !== undefined) clb(); });
    },
    getAllJobInProgress() {
      MainEndpoint.getAxiosInstance().get('affaire/get-all-in-progress').then((response) => {
        const length: number = response.data.length;
        let currentIndex = -1;
        const loadNextIndex = () => {
          currentIndex += 1;
          loadPercentageAffairesBus.trigger('loadingPercentage', Math.round((currentIndex * 100) / length));
          if (currentIndex >= length) {
            return;
          }
          this.loadJob(response.data[currentIndex], loadNextIndex);
        };
        loadNextIndex();
      });
    },
    betweenDate(initDeadLine: string, dateStart: Date, dateEnd: Date) {
      if (dateStart === undefined || dateEnd === undefined) {
        return false;
      }
      dateStart.setHours(0);
      const deadLine = new Date(initDeadLine);
      return dateEnd.getTime() >= deadLine.getTime() && deadLine.getTime() >= dateStart.getTime();
    },
    search(affaire: any, tabFilter: Array<any>) {
      let findPiece = true;
      let findAffaire = true;
      let findClient = true;
      let findDelai = true;
      tabFilter.map((search) => {
        if (search[1] !== undefined) {
          if (search[0] === 'inputPiece') {
            findPiece = affaire.Product.indexOf(search[1]) > -1;
          }
          if (search[0] === 'inputAffaire') {
            findAffaire = affaire.Job === parseInt(search[1], 10);
          }
          if (search[0] === 'inputClient') {
            findClient = affaire.CustomerCode.indexOf(search[1].toUpperCase()) > -1;
          }
          if (search[0] === 'inputDelai') {
            findDelai = this.betweenDate(affaire.InitDeadline, search[1], search[2]);
          }
        }
        return 0;
      });
      return findPiece && findAffaire && findClient && findDelai;
    },
    getAffaireByJobId(job: number): Affaire | undefined {
      let affaireFound = this.affaires.find((affaireSearch) => affaireSearch.Job === job) as Affaire;
      if (affaireFound !== undefined) {
        return affaireFound;
      }

      affaireFound = this.affaires.find((affaireSearch) => affaireSearch.Job === job) as Affaire;
      return affaireFound;
    },
  },
});

export default AffaireStore;
