import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "text",
    value: _ctx.value,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('value-changed', $event.target?.value))),
    class: "block border w-full p-4",
    placeholder: _ctx.placeholder
  }, null, 40, _hoisted_1))
}