enum BomStatut {
  UNDEFINED = 'Non défini',
  NOT_BOOKED = 'Aucune réserve',
  BOOKED = 'Réservé', // Réservé/Dispo stock
  NO_ORDER = 'Aucune commande', // Pas de commande
  ORDERED = 'Commandé', // Commandé (= Ligne de BC FOURNISSEUR qui est associé à la ligne de nomenclature
  // Réservation sur stock
  BOOKED_STOCK = 'Réservé stock',
  // d'achat, avec correspondance codes pièces et quantité)
  PARTIALLY_ORDERED = 'Commandé partiellement', // Commandé partiellement
  ORDERED_OTHER_REFERENCE = 'Commandé autre référence', // Commandé sous une autre référence
  RECEIVED = 'Reçu', // Recu
  PARTIALLY_RECEIVED = 'Partiellement reçu', // Partiellement recu
  // Statut des pièce qui viennent d'autre affaire, les productReservation
  PRODUCTION = 'En cours de production', // Pièce pas encore reçu
  PRODUCTION_DONE = 'Pièces fini', // Pièce terminer completement
  PRODUCTION_PARTIALLY = 'Pièces fini partiellement', // Pièce terminer partiellement
  ST_NO_ORDER = 'ST Aucune commande',
  ST_NO_ORDER_DELAY = 'ST Retard passage de commande', // Retard de passage de commande
  ST_ORDER_DONE = 'ST Commande passée',
  ST_PIECES_NOT_SENT = 'ST Pièces non envoyées',
  ST_PIECES_SENT = 'ST Pièces envoyées',
  ST_PIECES_SENT_PARTIALLY = 'ST Pièces envoyées partiellements',
  ST_PIECES_RECEIVED_PARTIALLY = 'ST Pièces reçus partiellements',
  ST_PIECES_RECEIVED = 'ST Pièces reçus',
}

export default BomStatut;
