import Axios, { AxiosInstance } from 'axios';

class EndPoint {
  private readonly endpoint : string;

  private readonly axiosInstance : AxiosInstance;

  constructor(endpoint : string) {
    this.endpoint = endpoint;

    this.axiosInstance = Axios.create({
      baseURL: this.getEndpoint(),
      timeout: 60000 * 3, // 3 minutes
    });
  }

  public getEndpoint() : string {
    return this.endpoint;
  }

  public getAxiosInstance() : AxiosInstance {
    return this.axiosInstance;
  }

  public setBearerToken(token: string): void {
    this.axiosInstance.defaults.headers.common = {
      ...this.axiosInstance.defaults.headers.common,
      Authorization: `Bearer ${token}`,
    };
  }
}

export default EndPoint;
