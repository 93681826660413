import Bom from '@/class/Bom';
import AffaireStore from '@/store/AffaireStore';
import AffaireRank from '@/class/AffaireRank';
import BomStatut from '@/enum/BomStatut';
import BomGroupStatus from '@/enum/BomGroupStatus';
import moment from 'moment';

class BomHelper {
  private static getBomsFromRankAndDate(rank: AffaireRank, date: Date): Array<Bom> {
    const boms = new Array<Bom>();
    rank.Postes.forEach((poste) => {
      const startDate = new Date(poste.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(poste.endDate);
      endDate.setHours(23, 59, 59, 999);
      if (date >= startDate && date <= endDate) {
        boms.push(...poste.Boms);
      }
    });

    // Recursivity
    rank.Childs.forEach((rankChild) => {
      boms.push(...this.getBomsFromRankAndDate(rankChild, date));
    });
    return boms;
  }

  public static getBomsFromDate(date: Date): Array<Bom> {
    const boms = new Array<Bom>();

    AffaireStore().affaires.forEach((affaire) => {
      affaire.Ranks.forEach((rank) => {
        boms.push(...this.getBomsFromRankAndDate(rank as AffaireRank, date));
      });
    });

    return boms;
  }

  private static colorLevel = {
    '-1': 'bg-gray-400',
    1: 'bg-green-400',
    2: 'bg-blue-400',
    3: 'bg-yellow-400',
    4: 'bg-red-400',
    5: 'bg-gray-700',
  };

  public static getBackgroundStatusColor(statut: BomStatut) {
    switch (statut) {
      case BomStatut.RECEIVED:
      case BomStatut.ST_PIECES_RECEIVED:
      case BomStatut.PRODUCTION_DONE:
      case BomStatut.BOOKED_STOCK:
        return BomHelper.colorLevel[1];
      case BomStatut.BOOKED:
      case BomStatut.ORDERED:
      case BomStatut.ORDERED_OTHER_REFERENCE:
      case BomStatut.ST_ORDER_DONE:
      case BomStatut.ST_PIECES_SENT:
        return BomHelper.colorLevel[2];
      case BomStatut.PARTIALLY_RECEIVED:
      case BomStatut.PARTIALLY_ORDERED:
      case BomStatut.PRODUCTION_PARTIALLY:
      case BomStatut.ST_PIECES_SENT_PARTIALLY:
      case BomStatut.ST_PIECES_RECEIVED_PARTIALLY:
        return BomHelper.colorLevel[3];
      case BomStatut.NOT_BOOKED:
      case BomStatut.NO_ORDER:
      case BomStatut.ST_NO_ORDER:
      case BomStatut.ST_PIECES_NOT_SENT:
      case BomStatut.PRODUCTION:
        return BomHelper.colorLevel[4];
      case BomStatut.ST_NO_ORDER_DELAY:
        return BomHelper.colorLevel[5];
      case BomStatut.UNDEFINED:
      default:
        return BomHelper.colorLevel[-1];
    }
  }

  public static getGroupStatusBom(status: BomStatut): BomGroupStatus {
    switch (status) {
      case BomStatut.RECEIVED:
      case BomStatut.ST_PIECES_RECEIVED:
      case BomStatut.PRODUCTION_DONE:
      case BomStatut.BOOKED_STOCK:
        return BomGroupStatus.RECEIVED;
      case BomStatut.BOOKED:
      case BomStatut.ORDERED:
      case BomStatut.ORDERED_OTHER_REFERENCE:
      case BomStatut.ST_ORDER_DONE:
      case BomStatut.ST_PIECES_SENT:
        return BomGroupStatus.PIECES_SENT;
      case BomStatut.PARTIALLY_RECEIVED:
      case BomStatut.PARTIALLY_ORDERED:
      case BomStatut.PRODUCTION_PARTIALLY:
      case BomStatut.ST_PIECES_SENT_PARTIALLY:
      case BomStatut.ST_PIECES_RECEIVED_PARTIALLY:
        return BomGroupStatus.PARTIALLY_ORDERED;
      case BomStatut.ST_NO_ORDER_DELAY:
        return BomGroupStatus.NO_ORDER_DELAY;
      case BomStatut.UNDEFINED:
        return BomGroupStatus.UNDEFINED;
      case BomStatut.NOT_BOOKED:
      case BomStatut.NO_ORDER:
      case BomStatut.ST_NO_ORDER:
      case BomStatut.ST_PIECES_NOT_SENT:
      case BomStatut.PRODUCTION:
      default:
        return BomGroupStatus.NO_ORDER;
    }
  }

  public static getStatusBom(status: BomGroupStatus, newStatus: BomGroupStatus): BomGroupStatus {
    if (status === newStatus) {
      return status;
    }

    if (status < newStatus) {
      return newStatus;
    }

    return status;
  }

  public static getIsNoOverdue(bom: Bom, posteStartDate: Date): boolean {
    const startDate = moment(posteStartDate);
    const deadlineDay = moment(bom.Deadline);

    return deadlineDay.isAfter(startDate);
  }

  public static getStatusBomColor(status: BomGroupStatus): string {
    switch (status) {
      case BomGroupStatus.RECEIVED:
        return 'bg-green-400';
      case BomGroupStatus.PARTIALLY_ORDERED:
        return 'bg-blue-400';
      case BomGroupStatus.PIECES_SENT:
        return 'bg-yellow-400';
      case BomGroupStatus.NO_ORDER_DELAY:
        return 'bg-red-400';
      case BomGroupStatus.UNDEFINED:
        return 'bg-white';
      case BomGroupStatus.NO_ORDER:
      default:
        return 'bg-gray-400';
    }
  }

  public static getInfosNomenclature(statusNomenclature: BomGroupStatus): string {
    switch (statusNomenclature) {
      case BomGroupStatus.RECEIVED:
        return 'Nomenclature Reçu.';
      case BomGroupStatus.PARTIALLY_ORDERED:
        return 'Nomenclature commandé partiellement';
      case BomGroupStatus.PIECES_SENT:
        return 'Pièces envoyées';
      case BomGroupStatus.NO_ORDER_DELAY:
        return 'Retard passage de commande';
      case BomGroupStatus.NO_ORDER:
        return 'Aucune commande de nomenclature faite';
      case BomGroupStatus.UNDEFINED:
        return 'Aucune nomenclature nécessaire';
      default:
        return 'Aucune informations';
    }
  }
}

export default BomHelper;
