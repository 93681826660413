
import { defineComponent } from 'vue';
import GuiInputText from '@/components/gui/InputText.vue';
import GuiH1 from '@/components/gui/H1.vue';
import UserAuthManager from '@/class/UserAuthManager';

export default defineComponent({
  name: 'LoginVue',
  components: {
    GuiH1,
    GuiInputText,
  },
  data() {
    return {
      username: 'SUPER',
      plainPassword: '',
    };
  },
  methods: {
    usernameChanged(value: string) {
      this.username = value;
    },
    plainPasswordChanged(value: string) {
      this.plainPassword = value;
    },
    connect() {
      UserAuthManager.login(this.username, this.plainPassword);
    },
  },
});
