import DateHelper from '@/helper/DateHelper';
import Bom from '@/class/Bom';
import CostCenter from '@/class/CostCenter';
import CostCenterHelper from '@/helper/CostCenterHelper';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import BaseRouting from '@/class/BaseRouting';
import BomHelper from '@/helper/BomHelper';
import ganttAddColumnBus from '@/bus/ganttAddColumnBus';
import RankClockingState from '@/enum/RankClockingState';
import BomGroupStatus from '@/enum/BomGroupStatus';
import moment from 'moment';
import BomStatut from '@/enum/BomStatut';
import SubTraintenceProblem from '@/enum/SubTraintenceProblem';

class RankRouting extends BaseRouting {
  public Phase = 0;

  public CostCenter = '';

  public CostCenterObject: CostCenter | undefined = undefined;

  public Designation1 = '';

  public listStatusNomenclature = [] as Array<[number, BomGroupStatus]>;

  public listStatusSousTraitance = [] as Array<[number, number, string, SubTraintenceProblem, number]>;

  public statusNomenclature = BomGroupStatus.UNDEFINED as BomGroupStatus;

  public moveDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<RankRouting>,
    dayMove: number,
    index: number,
    snapOnWeek = false,
    checkRoutingOnTop = true,
  ): number {
    const rank = this.Rank;
    if (rank === undefined) return 0;

    const diffBetweenStartAndEnd = DateHelper.getBusinessDatesCount(
      this.getStartDate(),
      this.getEndDate(),
    );
    const newStartDate = DateHelper.addDaysWithoutWeekEnd(this.getStartDate(), dayMove);
    const newEndDate = DateHelper.addDaysWithoutWeekEnd(newStartDate, diffBetweenStartAndEnd - 1);

    const newStartDateTest = new Date(newStartDate);
    newStartDateTest.setHours(0, 0, 0, 0);

    const newEndDateTest = new Date(newEndDate);
    newEndDateTest.setHours(0, 0, 0, 0);

    const maxDateTest = new Date(maxDate);
    maxDateTest.setHours(0, 0, 0, 0);

    const minDateTest = new Date(minDate);
    minDateTest.setHours(0, 0, 0, 0);

    if (newEndDateTest > maxDateTest && dayMove > 0) {
      ganttAddColumnBus.trigger('addWeekAfter', 1);
      maxDate.setDate(maxDateTest.getDate() + 7);
    }

    if (newStartDateTest < minDateTest && dayMove < 0) {
      ganttAddColumnBus.trigger('addWeekBefore', 1);
      minDate.setDate(minDateTest.getDate() - 7);
    }

    if (checkRoutingOnTop) {
      const routingOnTop: BaseRouting | undefined = routings[index - 1];
      if (routingOnTop !== undefined && dayMove < 0) {
        const routingOnTopEndDateTest = new Date(routingOnTop.endDate);
        routingOnTopEndDateTest.setHours(0, 0, 0, 0);
        if (routingOnTopEndDateTest >= newStartDateTest) {
          return 0;
        }
      }
    }

    // Bouge les enfants si les dates se superpposent :D
    if (dayMove < 0) {
      rank.Childs.forEach((rankChild) => {
        const maxDateRankChild = rankChild.getMaxDate();
        if (maxDateRankChild === undefined) return;
        if (maxDateRankChild >= newStartDateTest) {
          rankChild
            .getRoutingsMoveToShow()[0]
            .handleRouting(
              minDate,
              maxDate,
              rankChild.getRoutingsMoveToShow(),
              dayMove,
              0,
              snapOnWeek,
              checkRoutingOnTop,
            );
        }
      });
    }

    // Bouge les parents en même temps :D
    const rankParent = rank.Parent;
    if (rankParent !== undefined && dayMove > 0) {
      rank.Parent?.Postes.forEach((poste) => {
        if (poste !== undefined) {
          if (poste.startDate.toDateString() === newStartDateTest.toDateString()) {
            if (rankParent.getRoutingsMoveToShow().find((post) => post === poste) !== undefined) {
              rankParent
                .getRoutingsMoveToShow()[0]
                .handleRouting(
                  minDate,
                  maxDate,
                  rankParent.getRoutingsMoveToShow(),
                  dayMove,
                  0,
                  snapOnWeek,
                  checkRoutingOnTop,
                );
            }
          }
        }
      });
    }

    if (snapOnWeek) {
      this.startDate = DateHelper.setToMonday(newStartDate);
    } else {
      this.startDate = newStartDate;
    }

    if (snapOnWeek) {
      this.endDate = DateHelper.setToFriday(newEndDate);
    } else {
      this.endDate = newEndDate;
    }

    if (routings[index + 1] !== undefined) {
      // ༼ง ◉ _ ◉༽ง Desagréable avec nouvelle regle ༼ง ◉ _ ◉༽ง
      // routings[index + 1].handleRouting(
      //   minDate,
      //   maxDate,
      //   routings,
      //   dayMove,
      //   index + 1,
      //   snapOnWeek,
      //   checkRoutingOnTop,
      // );
      const previewsWeek = DateHelper.addDays(this.startDate, -7);

      // parent deplace c'est enfant si il a la meme date
      if (
        routings[index + 1].startDate.toDateString() === previewsWeek.toDateString()
        && dayMove > 0
      ) {
        routings[index + 1].handleRouting(
          minDate,
          maxDate,
          routings,
          dayMove,
          index + 1,
          snapOnWeek,
          checkRoutingOnTop,
        );
      }
    }

    if (routings[index - 1] !== undefined) {
      const sequelWeek = DateHelper.addDays(this.startDate, 7);

      // enfant deplace c'est parent si il a la meme date
      if (
        routings[index - 1].startDate.toDateString() === sequelWeek.toDateString()
        && dayMove < 0
      ) {
        routings[index - 1].handleRouting(
          minDate,
          maxDate,
          routings,
          dayMove,
          index - 1,
          snapOnWeek,
          checkRoutingOnTop,
        );
      }
    }

    return dayMove;
  }

  public handleRouting(
    minDate: Date,
    maxDate: Date,
    routings: Array<RankRouting>,
    dayMove: number,
    index: number,
    snapOnWeek = false,
    checkRoutingOnTop = true,
  ) {
    if (this.routingsWeekPlanning.length === 0) {
      this.moveDate(
        minDate,
        maxDate,
        routings,
        dayMove,
        index,
        snapOnWeek,
        checkRoutingOnTop,
      );
      return;
    }

    let totalTimeDefine = 0;
    let isStart = false;
    let isAllFinished = true;
    let maxStartDate = moment('1970-01-01');
    let isPlannified = false;

    this.routingsWeekPlanning.forEach((weekPlanning) => {
      weekPlanning.routingsDayPlanning.forEach((dayPlanning) => {
        if (!dayPlanning.isNotFinishReplaned) {
          totalTimeDefine += dayPlanning.minutes;
        }

        if (!dayPlanning.isFinished) {
          isAllFinished = false;
        }

        if (dayPlanning.totalTimeClocking > 0 || dayPlanning.isFinished) {
          isStart = true;

          const dateStart = moment(dayPlanning.startDate);

          if (dateStart.isAfter(maxStartDate)) {
            maxStartDate = dateStart;
          }

          if (!dayPlanning.isFinished) {
            dayPlanning.setIsNotFinishReplaned(true);
          }
        } else if (dayPlanning.totalTimeClocking === 0) {
          const indexRouting = this.routingsWeekPlanning.indexOf(weekPlanning);
          this.routingsWeekPlanning.splice(indexRouting, 1);
          dayPlanning.deleteToApi();
          weekPlanning.remove();
          isPlannified = true;
        }
      });
    });

    if ((totalTimeDefine < this.ManuTime) || !isAllFinished || isPlannified) {
      this.setIsReplanned(true);
    }

    if (isStart) {
      if (dayMove > 0) {
        this.moveEndDate(
          maxStartDate.toDate(),
          maxDate,
          routings,
          dayMove,
          index,
          snapOnWeek,
        );
      }
    } else {
      this.moveDate(
        minDate,
        maxStartDate.toDate(),
        routings,
        dayMove,
        index,
        snapOnWeek,
        checkRoutingOnTop,
      );
    }
  }

  public moveEndDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<RankRouting>,
    dayMove: number,
    index: number,
    snapOnWeek = false,
  ): void {
    let newEndDate = DateHelper
      .addDaysWithoutWeekEnd(this.getEndDate(), dayMove);
    if (newEndDate < this.getStartDate()) {
      this.endDate = new Date(this.getStartDate());
      return;
    }

    newEndDate = new Date(DateHelper.max(DateHelper.min(newEndDate, maxDate), this.startDate));

    let movedDay = DateHelper.getBusinessDatesCount(this.endDate, newEndDate) - 1;

    if (movedDay === -1) {
      movedDay = -(DateHelper.getBusinessDatesCount(newEndDate, this.endDate) - 1);
    }

    if (snapOnWeek) {
      this.endDate = DateHelper.setToFriday(newEndDate);
    } else {
      this.endDate = newEndDate;
    }

    this.recalculateWeeksHoursDistributor(this.getManuTimeWithCoef());

    if (routings[index + 1] !== undefined) {
      routings[index + 1].moveDate(minDate, maxDate, routings, movedDay, index + 1, snapOnWeek);
    }
  }

  public moveStartDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<RankRouting>,
    dayMove: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    index: number,
    snapOnWeek = false,
  ): void {
    const newStartDate = DateHelper.addDaysWithoutWeekEnd(this.getStartDate(), dayMove);

    if (snapOnWeek) {
      this.startDate = DateHelper.setToMonday(
        DateHelper.min(DateHelper.max(newStartDate, minDate), this.endDate),
      );
    } else {
      this.startDate = DateHelper.min(DateHelper.max(newStartDate, minDate), this.endDate);
    }

    this.recalculateWeeksHoursDistributor(this.getManuTimeWithCoef());
  }

  public checkRouting(startDate: Date, endDate: Date) {
    let totalTimeDefine = 0;
    let isStart = false;
    let isAllFinished = true;
    let maxStartDate = moment('1970-01-01');
    let maxFinishedDate = moment('1970-01-01');

    this.routingsWeekPlanning.forEach((weekPlanning) => {
      weekPlanning.routingsDayPlanning.forEach((dayPlanning) => {
        totalTimeDefine += dayPlanning.minutes;
        const dateStart = moment(dayPlanning.startDate);
        const dateEnd = moment(dayPlanning.getEndDate());

        if (!dayPlanning.isFinished) {
          isAllFinished = false;
        }

        if (dateStart.isBefore(startDate) || dateEnd.isBefore(endDate)) {
          if (dayPlanning.totalTimeClocking > 0) {
            isStart = true;

            if (dateStart.isAfter(maxStartDate)) {
              maxStartDate = dateStart;
            }

            if (dateEnd.isAfter(maxFinishedDate)) {
              maxFinishedDate = dateEnd;
            }

            if (!dayPlanning.isFinished) {
              dayPlanning.setIsNotFinishReplaned(true);
            }
          } else {
            const indexRouting = this.routingsWeekPlanning.indexOf(weekPlanning);
            this.routingsWeekPlanning.splice(indexRouting, 1);
            dayPlanning.deleteToApi();
            weekPlanning.remove();
          }
        }
      });
    });

    if (totalTimeDefine * 60 < this.ManuTime || !isAllFinished) {
      this.setIsReplanned(true);
    }

    if (isStart) {
      if (this.startDate > maxStartDate.toDate()) {
        return [maxStartDate.toDate(), endDate];
      }

      if (this.endDate < maxFinishedDate.toDate()) {
        return [startDate, maxFinishedDate.toDate()];
      }
    }

    return [startDate, endDate];
  }

  public fillFromJSON(data: any): void {
    super.fillFromJSON(data);
    if (data.startDate) {
      this.startDate = new Date(data.startDate);
    }
    if (data.endDate) {
      this.endDate = new Date(data.endDate);
    }
    this.Boms = new Array<Bom>();
    data.Boms.forEach((bom: any) => {
      const bomObj = new Bom(this);
      bomObj.fillFromJSON(bom);
      this.Boms.push(bomObj);
    });
    this.CostCenterObject = CostCenterHelper.getCostCenterByCode(this.CostCenter);

    if (data.weeksPlanning !== undefined) {
      data.weeksPlanning.forEach((dataWp: any) => {
        const wr = new RoutingWeekPlanning(this, true);
        wr.fillFromJSON(dataWp);
      });
    }

    if (data.weeksHoursDistributor) {
      this.weeksHoursDistributor = JSON.parse(data.weeksHoursDistributor);
    }

    if (data.isReplanned !== undefined) {
      this.isReplanned = data.isReplanned;
    }
  }

  public getBomsToShow(): Array<Bom> {
    return this.Boms.filter((bom) => {
      if (bom.Item.startsWith('TO*')) {
        return false;
      }
      return true;
    });
  }

  public getManuTimeWithCoef() {
    if (this.Rank === undefined) {
      return this.ManuTime;
    }
    return this.ManuTime * this.Rank.Coef;
  }

  public getLateStatusBom(maxTime: Date | undefined) {
    let startDate = new Date(this.startDate);
    this.listStatusNomenclature = [];
    this.listStatusSousTraitance = [];

    if (maxTime !== undefined) {
      startDate = maxTime;
    }

    // eslint-disable-next-line vue/no-mutating-props
    this.getBomsToShow().forEach((bom) => {
      // - [x] Si la commande de la sous-traitance n’a pas été réalisée une semaine avant son début.
      if (bom.PurchaseOrders === undefined || bom.PurchaseOrders.length === 0) {
        const now = moment();

        now.set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        });

        now.add(1, 'week');
        now.add(-1, 'second');

        if (now.isAfter(moment(bom.Deadline))) {
          const doubles = this.listStatusSousTraitance.find((item) => item[0] === this.IdRouting);
          if (doubles === undefined) {
            this.listStatusSousTraitance.push([this.IdRouting, 0, '--', SubTraintenceProblem.NOT_REALIZED, 0]);
          }
        }
      } else {
        // maxTime
        bom.getLateStatusBom(this.startDate);
      }

      const status = BomHelper.getGroupStatusBom(bom.getStatut()[bom.getStatut().length - 1]);

      let isOverdue = false;

      switch (status) {
        case BomGroupStatus.RECEIVED:
        case BomGroupStatus.UNDEFINED:
          break;
        case BomGroupStatus.PARTIALLY_ORDERED:
        case BomGroupStatus.PIECES_SENT:
        case BomGroupStatus.NO_ORDER_DELAY:
          isOverdue = BomHelper.getIsNoOverdue(bom, startDate);
          break;
        default:
          isOverdue = true;
          break;
      }

      if (
        bom.Family === 'STTS'
        && this.startDate < moment().toDate()
        && (bom.getStatut()[bom.getStatut().length - 1] !== BomStatut.ST_PIECES_SENT
          || bom.getStatut()[bom.getStatut().length - 1] !== BomStatut.ST_PIECES_RECEIVED)
      ) {
        this.addListNomenclature(bom, status);
      } else if (isOverdue) {
        this.addListNomenclature(bom, status);
      }

      bom.listStatusSousTraitance.forEach((statusSousTraitance) => {
        const doubles = this.listStatusSousTraitance.find((item) => item[1] === bom.IdBom);
        if (doubles === undefined) {
          this.listStatusSousTraitance.push(statusSousTraitance);
        }
      });
    });
  }

  public addListNomenclature(bom: Bom, status: BomGroupStatus): void {
    const doubles = this.listStatusNomenclature.find((item) => item[0] === bom.IdBom);
    if (doubles === undefined) {
      this.listStatusNomenclature.push([bom.IdBom, status]);
    }
    this.statusNomenclature = BomHelper.getStatusBom(this.statusNomenclature, status);
  }

  public getStatusBom(): BomGroupStatus {
    let status = BomGroupStatus.UNDEFINED;
    let newStatus = 0;

    // eslint-disable-next-line vue/no-mutating-props
    this.getBomsToShow().forEach((bom) => {
      newStatus = BomHelper.getGroupStatusBom(bom.getStatut()[bom.getStatut().length - 1]);
      status = BomHelper.getStatusBom(status, newStatus);
    });
    if (this.getBomsToShow() === undefined || this.getBomsToShow().length === 0) {
      return BomGroupStatus.UNDEFINED;
    }

    return status;
  }

  public getStatusTaskColor(): string {
    switch (this.getStatusTask()) {
      case RankClockingState.FINISHED:
        return 'bg-emerald-500';
      case RankClockingState.IN_PROGRESS:
        return 'bg-purple-400';
      case RankClockingState.STARTED:
        return 'bg-orange-500';
      case RankClockingState.NOT_STARTED:
      default:
        return 'bg-gray-400';
    }
  }

  public getStatusTaskText() {
    switch (this.getStatusTask()) {
      case RankClockingState.FINISHED:
        return 'Terminé';
      case RankClockingState.IN_PROGRESS:
        return 'En cours';
      case RankClockingState.REPLANNED:
        return 'A replanifier';
      case RankClockingState.STARTED:
        return 'En pause';
      case RankClockingState.NOT_STARTED:
      default:
        return 'Non démarré';
    }
  }
}

export default RankRouting;
