enum BomGroupStatus {
  UNDEFINED = -1,
  RECEIVED = 0,
  PARTIALLY_ORDERED = 1,
  PIECES_SENT = 2,
  NO_ORDER_DELAY = 3,
  NO_ORDER = 4,
}

export default BomGroupStatus;
