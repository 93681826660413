import EndPoint from '@/endpoints/endpoint';
import { AxiosError } from 'axios';
import router from '@/router';
import Notification from '@/helper/Notification';
import UserAuthManager from '@/class/UserAuthManager';
import UserAuthStore from '@/store/UserAuthStore';

class MainEndPoint extends EndPoint {
  loadAxiosMiddleWare() {
    const userAuthStore = UserAuthStore();
    this.getAxiosInstance().interceptors.response.use(
      (r) => Promise.resolve(r),
      (error : AxiosError) => {
        if (error.response === undefined) {
          return Promise.reject(error);
        }
        if (error.response.status === 401 && userAuthStore.token !== undefined) {
          if (router.currentRoute.value.name !== 'Login') {
            Notification.notifyInfo('Votre session à expiré, merci de vous reconnecter');
            UserAuthManager.logout(false);
          }
          return false;
        }
        return Promise.reject(error);
      },
    );
  }
}
const MainEndpoint = new MainEndPoint(process.env.VUE_APP_API_ENDPOINT);
export default MainEndpoint;
