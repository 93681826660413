
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GuiInputText',
  props: {
    placeholder: {
      type: String,
      default: () => '',
    },
    value: {
      type: String,
      default: () => '',
    },
  },
});
