import Serializable from '@/helper/SerializationHelper';
import SupplierDeliveryNotes from '@/class/SupplierDeliveryNotes';

class PurchaseOrder extends Serializable {
  public Idorder = 0;

  public Ordernr = 0;

  public Date = '';

  public Supplier = '';

  public Item = '';

  public Product = '';

  public Description1 = '';

  public Description2 = '';

  public Description3 = '';

  public Qty = 0;

  public Unitqty = '';

  public Price = 0;

  public UnitPrice = '';

  public Family = '';

  public Constprice = 0;

  public Devise = 0;

  public Idwarehouse = 0;

  public SupplierDeliveryNotes: any[] = [];

  public SupplierDeliveryNotesObject = new Array<SupplierDeliveryNotes>();

  fillFromJSON(data: any) {
    super.fillFromJSON(data);
    this.SupplierDeliveryNotes.forEach((sdn) => {
      const sdno = new SupplierDeliveryNotes();
      sdno.fillFromJSON(sdn);
      this.SupplierDeliveryNotesObject.push(sdno);
    });
  }
}

export default PurchaseOrder;
