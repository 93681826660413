import MainEndpoint from '@/endpoints/main';
import Notify from '@/helper/Notification';
import { AxiosError } from 'axios';
import Serializable from '@/helper/SerializationHelper';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';
import UserHelper from '@/helper/UserHelper';
import BaseRouting from '@/class/BaseRouting';

class RoutingWeekPlanning extends Serializable {
  public date = new Date();

  public minutes = 0;

  // En gros, dedans on peut soit avoir un GroupedRouting, ou alors un RankRouting
  // Pour savoir dans le code c'est quel type, on peut utiliser
  // instanceof RankRouting ou instanceof GroupedRouting
  // => Tous les deux extends BaseRouting
  public baseRouting : BaseRouting;

  public idRouting = 0;

  public internalId: undefined | number = undefined;

  public routingsDayPlanning = new Array<RoutingDayPlanning>();

  public comment = '';

  constructor(rankRouting: BaseRouting, shouldAddToRouting = false) {
    super();
    this.baseRouting = rankRouting;
    if (shouldAddToRouting) {
      rankRouting.routingsWeekPlanning.push(this);
    }
  }

  public save(clb: (() => void) | undefined = undefined) {
    const data = {
      date: this.date.toUTCString(),
      minutes: this.minutes,
      idRouting: this.baseRouting.IdRouting,
      internalId: this.internalId,
    };
    MainEndpoint.getAxiosInstance().post('week-planning/save', data).then(
      (response) => {
        this.internalId = response.data.internalId;
        if (clb !== undefined) clb();
      },
    ).catch((error: AxiosError) => {
      Notify.notifyFailure(`Impossible de sauvegarder la programmation. ${error.response?.data.error}`);
    });
  }

  public remove() {
    MainEndpoint.getAxiosInstance().delete(`week-planning/delete/${this.internalId}`).then(
      () => {
        this.internalId = undefined;
      },
    ).catch((error: AxiosError) => {
      Notify.notifyFailure(`Impossible de supprimer la programmation. ${error.response?.data.error}`);
    });
  }

  fillFromJSON(data: any) {
    super.fillFromJSON(data);
    this.date = new Date(data.date);

    data.daysPlanning.forEach((dataRdp: any) => {
      const operator = UserHelper.getUserById(dataRdp.operatorUser);
      if (operator === undefined) return;
      const dayPlanning = new RoutingDayPlanning(
        this,
        operator,
        new Date(dataRdp.startDateUtc),
      );
      dayPlanning.fillFromJSON(dataRdp);
      this.routingsDayPlanning.push(dayPlanning);
    });
  }

  public getTotalMinutesDayPlanning() {
    let total = 0;
    this.routingsDayPlanning.forEach((rdp) => {
      total += rdp.minutes;
    });
    return total;
  }
}

export default RoutingWeekPlanning;
