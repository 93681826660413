import Serializable from '@/helper/SerializationHelper';
import CostCenterHelper from '@/helper/CostCenterHelper';

class CostCenter extends Serializable {
  public static readonly regroupedGroupId : {[key: string]: number} = {
    LASER: -1,
    PLIAGE: -2,
    GRAVAGE: -3,
  };

  public static readonly groups : {[key: string] : Array<string>} = {
    CONTROLE: [
      'CONTR',
      'CT3D',
      'CTSOU',
    ],
    'DEBIT/PERCAGE': [
      'DEBIT',
      'PRCAG',
    ],
    GRAVAGE: [
      'GRAVA',
    ],
    LASER: [
      'COUPE',
      'LASER',
    ],
    MAINTENANCE: [
      'ZMAIN',
    ],
    'MONTAGE DAS-B': [
      'MOPR',
    ],
    'MONTAGE DAS-C': [
      'MOME',
    ],
    PLIAGE: [
      'PLI',
    ],
    'SOUDAGE ACIER': [
      'MDOAC',
    ],
    'SOUDAGE ALU': [
      'MDOAL',
    ],
    'SOUDAGE FSW': [
      'CHAFS',
      'FSW',
      'OPFSW',
    ],
    'SOUDAGE INOX': [
      'MDOIN',
    ],
    'SOUDAGE ROBOT': [
      'PROGR',
      'ROBOT',
    ],
    USINAGE: [
      'FRAIS',
      'TOURN',
      'USINA',
    ],
  };

  Code = '';

  Designation = '';

  Section = '';

  CLHR = 0;

  MTLHR = 0;

  ReleaseDate = '';

  Type = '';

  CapacityHD = 0;

  CapacityM = 0;

  Group : undefined | string = undefined;

  Color = 'pink';

  private determineGroup() {
    Object.keys(CostCenter.groups).every((key) => {
      if ((CostCenter.groups[key]).includes(this.Code)) {
        this.Group = key;
        return false;
      }
      return true;
    });
  }

  public static getGroups(): Array<string> {
    return Object.keys(CostCenter.groups);
  }

  public getBackgroundColor():string {
    return `bg-${this.Color}-500`;
  }

  public getTextColor():string {
    return `text-${this.Color}-600`;
  }

  private determineColorFromGroup() {
    if (this.Group === undefined) return;
    this.Color = CostCenterHelper.getColorFromGroup(this.Group);
  }

  // Override
  fillFromJSON(data: any) {
    super.fillFromJSON(data);
    this.determineGroup();
    this.determineColorFromGroup();
  }

  // Override
  fillFromJSONString(data: string) {
    super.fillFromJSONString(data);
    this.determineGroup();
    this.determineColorFromGroup();
  }
}

export default CostCenter;
