

import { defineComponent } from 'vue';
import PlanificateurChangeView from '@/bus/planificateurChangeView';

export default defineComponent({
  name: 'App',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      env: process.env.VUE_APP_ENV,
    };
  },
  methods: {
    showWhatsNew() {
      PlanificateurChangeView.trigger('changeView', 7);
    },
  },
});
