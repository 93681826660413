import { defineStore } from 'pinia';

const GanttStore = defineStore('gantt', {
  state: () => ({
    options: {
      JITRendering: false,
      showCostCenter: false,
      stickyCostCenter: true,
      stickyTopBar: true,
      rankGuide: true,
    },
    weeksFilter: new Array<Date>(),
    costCenterGroupFilter: new Array<string>(),
  }),
  actions: {
    addWeekFilter(date: Date) {
      this.weeksFilter.push(date);
    },
    removeWeekFilter(date: Date) {
      this.weeksFilter = this.weeksFilter.filter((f) => f.valueOf() !== date.valueOf());
    },
    removeAllWeekFilter() {
      this.weeksFilter.splice(0, this.weeksFilter.length);
    },
    getNumberOfFilter() {
      return this.weeksFilter.length + this.costCenterGroupFilter.length;
    },
    addCostCenterGroupFilter(costCenterGroup: string) {
      this.costCenterGroupFilter.push(costCenterGroup);
    },
    removeCostCenterGroupFilter(costCenterGroup: string) {
      this.costCenterGroupFilter.splice(this.costCenterGroupFilter.indexOf(costCenterGroup), 1);
    },
    removeAllCostCenterGroupFilter() {
      this.costCenterGroupFilter.splice(0, this.costCenterGroupFilter.length);
    },
    removeAllFilter() {
      this.removeAllCostCenterGroupFilter();
      this.removeAllWeekFilter();
    },
  },
});

export default GanttStore;
