import Notiflix from 'notiflix';
import MainEndpoint from '@/endpoints/main';
import { AxiosError, AxiosResponse } from 'axios';
import router from '@/router';
import Affaire from '@/class/Affaire';
import Notification from '@/helper/Notification';
import CostCenter from '@/class/CostCenter';
import User from '@/class/User';
import AffaireStore from '@/store/AffaireStore';
import CostCenterStore from '@/store/CostCenterStore';
import UserAuthStore from '@/store/UserAuthStore';
import UserStore from '@/store/UserStore';
import UserEntity from '@/class/UserEntity';

class UserAuthManager {
  public static isLoggedIn() : boolean {
    const userAuthStore = UserAuthStore();
    return userAuthStore.user !== undefined
      && userAuthStore.token !== undefined;
  }

  public static startKeepAliveSession() {
    setInterval(() => {
      if (UserAuthManager.isLoggedIn()) {
        MainEndpoint.getAxiosInstance().get('keep-alive/');
      }
    }, 1000 * 60); // Toutes les minutes.
  }

  public static tryRestoreSession() : void {
    const userAuthStore = UserAuthStore();

    const userJSON = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    if (token !== null && userJSON !== null) {
      const user = new User();
      user.fillFromJSONString(userJSON as string);

      MainEndpoint.setBearerToken(token as string);

      MainEndpoint.getAxiosInstance().get('keep-alive/').then(() => {
        const lastRoute = localStorage.getItem('lastRoute');
        if (lastRoute === null) {
          return;
        }
        userAuthStore.user = user;
        userAuthStore.token = token as string;
        router.push(lastRoute);
        Notiflix.Notify.success('Votre session vient d\'être restauré !');
      });
    }
  }

  public static login(username: string, plainPassword: string) : void {
    const userAuthStore = UserAuthStore();
    Notiflix.Loading.circle('Connexion en cours', { svgColor: 'white' });
    MainEndpoint.getAxiosInstance().post('login', {
      username,
      password: plainPassword,
    }).then((response: AxiosResponse) => {
      userAuthStore.token = response.data.token;
      const user = new User();
      user.fillFromJSON(response.data.user);
      userAuthStore.user = user;
      // Authorize future request by providing valid bearer token
      MainEndpoint.setBearerToken(response.data.token);
      // Store token and user inside the navigator
      localStorage.setItem('user', user.toJson());
      localStorage.setItem('token', response.data.token);
      // Redirect to the good route from his role
      switch (response.data.user.role) {
        case 'admin':
          // TODO: push to BackOffice when have different role accounts
          router.push('Planificateur');
          break;
        case 'planificateur':
          router.push('Planificateur');
          break;
        case 'coordinateur':
          router.push('Coordinateur');
          break;
        case 'operateur':
        default:
          router.push('Operateur');
          break;
      }
    }).catch((error: AxiosError) => {
      if (error.response === undefined) {
        Notiflix.Notify.failure('Impossible de se connecter au serveur !');
        return;
      }
      switch (error.response.status) {
        case 401:
          Notiflix.Notify.failure('Identifiants incorrects !');
          break;
        case 500:
          Notiflix.Notify.failure('Erreur interne serveur !');
          break;
        default:
          Notiflix.Notify.failure('Impossible de se connecter, erreur inconnue !');
          break;
      }
    })
      .finally(() => Notiflix.Loading.remove());
  }

  public static handleLogout(): void {
    const userAuthStore = UserAuthStore();
    userAuthStore.token = undefined;
    userAuthStore.user = undefined;
    AffaireStore().affaires = new Array<Affaire>();
    CostCenterStore().costCenter = new Array<CostCenter>();
    UserStore().users = new Array<UserEntity>();
    localStorage.removeItem('user');
    localStorage.removeItem('lastRoute');
    localStorage.removeItem('token');
    Notification.notifySuccess('Vous venez de vous déconnecter');
  }

  public static logout(showConfirm = false) : void {
    if (showConfirm) {
      Notiflix.Confirm.show('Déconnexion', 'Voulez-vous vraiment vous déconnecter ?', 'Oui', 'Non', () => {
        router.push('Logout');
      }, undefined, { titleColor: 'red', okButtonBackground: 'rgb(255,50,50)', width: '400px' });
    } else {
      router.push('Logout');
    }
  }
}
export default UserAuthManager;
