import Serializable from '@/helper/SerializationHelper';

class ProductionReservation extends Serializable {
  public Idreservation = 0;

  public Product = '';

  public Qty = 0;

  public Job = 0;
}

export default ProductionReservation;
