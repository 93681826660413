import { defineStore } from 'pinia';
import MainEndpoint from '@/endpoints/main';
import Notify from '@/helper/Notification';
import CostCenter from '@/class/CostCenter';

const CostCenterStore = defineStore('costcenter', {
  state: () => ({
    costCenter: new Array<CostCenter>(),
  }),
  getters: {
  },
  actions: {
    setCostCenters(data: Array<CostCenter>) {
      this.costCenter = data;
    },
    loadCostCenters() {
      return MainEndpoint.getAxiosInstance()
        .get('costcenter')
        .then((response) => {
          const newCostCenters = new Array<CostCenter>();
          response.data.forEach((c: any) => {
            const costCenterObject = new CostCenter();
            costCenterObject.fillFromJSON(c);
            newCostCenters.push(costCenterObject);
          });
          this.setCostCenters(newCostCenters);
        })
        .catch((reason) => {
          console.log(reason);
          Notify.notifyFailure('Impossible de récuperer la liste des CostCenters');
        });
    },
  },
});

export default CostCenterStore;
