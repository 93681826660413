class DateHelper {
  public static addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  public static getBusinessDatesCount(startDate: Date, endDate: Date): number {
    const startDateTime0 = new Date(startDate);
    startDateTime0.setHours(0, 0, 0, 0);
    const endDateTime0 = new Date(endDate);
    endDateTime0.setHours(0, 0, 0, 0);

    let count = 0;
    const curDate = new Date(startDateTime0.getTime());
    while (curDate <= endDateTime0) {
      const dayOfWeek = curDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count += 1;
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  public static getWeekDatesCount(startDate: Date, endDate: Date): number {
    return Math.ceil(this.getBusinessDatesCount(
      DateHelper.setToMonday(new Date(startDate)),
      endDate,
    )
      / 5);
  }

  public static addDaysWithoutWeekEnd(date: Date, days: number): Date {
    const result = new Date(date);
    if (days > 0) {
      for (let i = 0; i < days; i += 1) {
        if (result.getDay() === 5) {
          result.setDate(result.getDate() + 3);
        } else if (result.getDay() === 6) {
          result.setDate(result.getDate() + 2);
        } else {
          result.setDate(result.getDate() + 1);
        }
      }
    } else {
      for (let i = 0; i > days; i -= 1) {
        if (result.getDay() === 1) {
          result.setDate(result.getDate() - 3);
        } else if (result.getDay() === 0) {
          result.setDate(result.getDate() - 2);
        } else {
          result.setDate(result.getDate() - 1);
        }
      }
    }
    return result;
  }

  public static setToMonday(date: Date): Date {
    const day = date.getDay() || 7;
    if (day !== 1) date.setHours(-24 * (day - 1));
    return date;
  }

  public static setToFriday(date: Date): Date {
    const currentDay = date.getDay();
    const distance = 5 - currentDay;
    date.setDate(date.getDate() + distance);
    return date;
  }

  public static weekName(date: Date): string {
    switch (date.getDay()) {
      case 1:
        return 'Lun';
      case 2:
        return 'Mar';
      case 3:
        return 'Mer';
      case 4:
        return 'Jeu';
      case 5:
        return 'Ven';
      case 6:
        return 'Sam';
      case 0:
        return 'Dim';
      default:
        return 'Unknown';
    }
  }

  public static max(a: Date, b: Date): Date {
    return a > b ? a : b;
  }

  public static min(a: Date, b: Date): Date {
    return a < b ? a : b;
  }

  public static isDateInRange(date: Date, min: Date, max: Date): boolean {
    return date >= min && date <= max;
  }

  public static getWeek(date: Date): number {
    const dateCopy = new Date(date.getTime());
    dateCopy.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    // eslint-disable-next-line no-mixed-operators
    dateCopy.setDate(dateCopy.getDate() + 3 - (dateCopy.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(dateCopy.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((dateCopy.getTime() - week1.getTime()) / 86400000
      // eslint-disable-next-line no-mixed-operators
      - 3 + (week1.getDay() + 6) % 7) / 7);
  }
}

export default DateHelper;
