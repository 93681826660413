import Serializable from '@/helper/SerializationHelper';

class AffaireBase extends Serializable {
  public Designation1 = '';

  public Job = 0;

  public CustomerCode = '';

  public InternalOrder = '';

  public Order = '';

  public Type = 0;

  public IntDeadline = '';

  public InitDeadline = '';

  public Product = '';

  public ChildsCostCenter = Array<string>();

  public isNewAffaire = false;

  public isOverDelays = false;
}

export default AffaireBase;
