import CostCenter from '@/class/CostCenter';
import AffaireRank from '@/class/AffaireRank';
import DateHelper from '@/helper/DateHelper';
import Affaire from '@/class/Affaire';
import AffaireStore from '@/store/AffaireStore';
import CostCenterStore from '@/store/CostCenterStore';
import RankRouting from '@/class/RankRouting';
import MainEndpoint from '@/endpoints/main';
import Notiflix from 'notiflix';

class CostCenterHelper {
  private static addCostCenterFromRank(
    addedCode: Array<string>,
    costCenterToSow: Array<CostCenter>,
    rank: AffaireRank,
  ): void {
    rank.Postes.forEach((routing) => {
      if (addedCode.includes(routing.CostCenter)) return;
      addedCode.push(routing.CostCenter);
      costCenterToSow.push(
        CostCenterStore().costCenter.filter(
          (costCenterFilter) => costCenterFilter.Code === routing.CostCenter,
        )[0] as CostCenter,
      );
    });
    rank.Childs.forEach((rankChild) => {
      CostCenterHelper.addCostCenterFromRank(addedCode, costCenterToSow, rankChild);
    });
  }

  public static getCostCenterToShow(): Array<CostCenter> {
    const addedCode = new Array<string>();
    const costCenterToShow = new Array<CostCenter>();

    AffaireStore().affaires.forEach((affaire) => {
      affaire.Ranks.forEach((rank) => {
        CostCenterHelper.addCostCenterFromRank(addedCode, costCenterToShow, rank as AffaireRank);
      });
    });

    return costCenterToShow.filter((c) => c !== undefined && c.Group !== undefined);
  }

  public static getCostCenterToShowGroups(): Array<string> {
    const filteredCostCenters = this.getCostCenterToShow();
    const costCentersCode: Array<string> = filteredCostCenters.map((c) => <string> c.Group);
    const costCentersCodeSort = costCentersCode.sort((a, b) => (CostCenterHelper.sortCostCenter(a, b)));

    return costCentersCodeSort.reduce((a, b) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, new Array<string>());
  }

  public static sortCostCenter(a: string, b: string) {
    const costCentersMapping = [
      { order: 1, name: 'LASER' },
      { order: 2, name: 'PLIAGE' },
      { order: 3, name: 'GRAVAGE' },
      { order: 4, name: 'DEBIT/PERCAGE' },
      { order: 5, name: 'USINAGE' },
      { order: 6, name: 'SOUDAGE ACIER' },
      { order: 7, name: 'SOUDAGE INOX' },
      { order: 8, name: 'SOUDAGE ALU' },
      { order: 9, name: 'CONTROLE' },
      { order: 10, name: 'MONTAGE DAS-A' },
      { order: 11, name: 'MONTAGE DAS-B' },
      { order: 12, name: 'MONTAGE DAS-C' },
    ];
    const aMap = costCentersMapping.find((item) => item.name === a);
    const bMap = costCentersMapping.find((item) => item.name === b);

    if (aMap === undefined && bMap === undefined) {
      return 0;
    }

    if (aMap === undefined) {
      return 1;
    }

    if (bMap === undefined) {
      return -1;
    }

    if (aMap.order > bMap.order) {
      return 1;
    }

    if (aMap.order < bMap.order) {
      return -1;
    }

    return 0;
  }

  private static addHoursPerDateByCostCenter(
    date: Date,
    costCenter: CostCenter,
    rank: AffaireRank,
  ): number {
    let hour = 0;
    rank.Postes.filter((poste) => poste.CostCenter === costCenter.Code)
      .forEach((poste) => {
        const startDate = new Date(poste.startDate);
        startDate.setHours(0, 0, 0, 0);

        if (date >= startDate && poste.endDate >= date) {
          hour += poste.getManuTimeWithCoef()
              / DateHelper.getBusinessDatesCount(startDate, poste.endDate);
        }
      });
    rank.Childs.forEach((rankChild) => {
      hour += CostCenterHelper.addHoursPerDateByCostCenter(date, costCenter, rankChild);
    });
    return hour;
  }

  public static getHoursInDayByCostCenter(costCenter: CostCenter, date: Date): Promise<number> {
    return new Promise((resolve) => {
      let totalHour = 0;
      AffaireStore().affaires.forEach((affaire) => {
        affaire.Ranks.forEach((rank) => {
          totalHour += CostCenterHelper
            .addHoursPerDateByCostCenter(date, costCenter, rank as AffaireRank);
        });
      });
      resolve(Math.round(totalHour * 100) / 100);
    });
  }

  public static getHoursInDayByGroup(group: string, date: Date): Promise<number> {
    return new Promise<number>(((resolve) => {
      let totalHour = 0;
      CostCenterHelper.getCostCenterToShow()
        .filter((c) => c.Group === group)
        .forEach((costCenter) => {
          AffaireStore().affaires.forEach((affaire) => {
            affaire.Ranks.forEach((rank) => {
              totalHour += CostCenterHelper
                .addHoursPerDateByCostCenter(date, costCenter, rank as AffaireRank);
            });
          });
        });
      resolve(totalHour);
    }));
  }

  public static async getHoursInWeekByGroup(group: string, date: Date): Promise<number> {
    let total = 0;
    for (let i = 0; i < 5; i += 1) {
      const forDate = DateHelper.addDays(new Date(date), i);
      // eslint-disable-next-line no-await-in-loop
      total += await CostCenterHelper.getHoursInDayByGroup(group, forDate);
    }
    return total;
  }

  public static async getTotalHoursByGroup(yearNumber: number, weekNumberStart: number, weekNumberEnd: number): Promise<Array<any>> {
    try {
      const response = await MainEndpoint.getAxiosInstance().get(`costcenter/get-time-task/${yearNumber}/${weekNumberStart}/${weekNumberEnd}`);
      return response.data;
    } catch (error) {
      Notiflix.Notify.failure('Une erreur est apparu lors du chargement des centres de coûts.');
      return [];
    }
  }

  public static getTextColorFromGroup(group: string): string {
    const cc = CostCenterStore().costCenter.filter((c) => c.Group === group);
    if (cc.length > 0) {
      return cc[0].getTextColor();
    }
    return '';
  }

  public static getBackgroundColorFromGroup(group: string): string {
    const cc = CostCenterStore().costCenter.filter((c) => c.Group === group);
    if (cc.length > 0) {
      return cc[0].getBackgroundColor();
    }
    return '';
  }

  public static getCostCenterCapacity(costCenter : CostCenter): number {
    return costCenter.CapacityHD * costCenter.CapacityM * 8;
  }

  public static getNumberOfHourChargePerRouting(
    affaire: Affaire,
    rank: AffaireRank | undefined,
    costCenter: CostCenter,
  ): number {
    let total = 0;
    if (rank === undefined) {
      affaire.Ranks.forEach((affaireRank) => {
        total += CostCenterHelper.getNumberOfHourChargePerRouting(affaire, affaireRank, costCenter);
      });
      return total;
    }

    // Recursivity
    rank.Childs.forEach((rankChild) => {
      total += CostCenterHelper.getNumberOfHourChargePerRouting(affaire, rankChild, costCenter);
    });

    rank.Postes.forEach((routing) => {
      if (routing.CostCenter !== costCenter.Code) {
        return;
      }
      total += routing.ManuTime
        / DateHelper.getBusinessDatesCount(routing.startDate, routing.endDate);
    });

    return total;
  }

  public static getCostCenterCharge(costCenter : CostCenter): number {
    let total = 0;
    AffaireStore().affaires.forEach((affaire) => {
      total += CostCenterHelper
        .getNumberOfHourChargePerRouting(affaire as Affaire, undefined, costCenter);
    });

    return total;
  }

  public static getCostCenterByCode(code: string): CostCenter | undefined {
    const costCenterList = CostCenterStore().costCenter.find((c) => c.Code === code);
    // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Array/find
    return costCenterList as CostCenter | undefined;
  }

  public static getCostCenterGroup(code: string) : string {
    let group = '';
    Object.keys(CostCenter.groups).every((key) => {
      if ((CostCenter.groups[key]).includes(code)) {
        group = key;
        return false;
      }
      return true;
    });
    return group;
  }

  private static getHoursInDayByGroupGroupedRouting(groupId: number, date: Date): number {
    let total = 0;
    AffaireStore().affaires.forEach((a) => {
      a.regroupedRoutings.forEach((gr) => {
        if (gr.regroupId !== groupId) return;
        const startDate = new Date(gr.startDate);
        startDate.setHours(0, 0, 0, 0);

        if (date >= startDate && gr.endDate >= date) {
          total += gr.ManuTime
            / DateHelper.getBusinessDatesCount(startDate, gr.endDate);
        }
      });
    });
    return total;
  }

  private static getHoursInWeekByGroupGroupedRouting(groupId: number, date: Date): number {
    let total = 0;
    for (let i = 0; i < 5; i += 1) {
      const forDate = DateHelper.addDays(new Date(date), i);
      total += CostCenterHelper.getHoursInDayByGroupGroupedRouting(
        groupId,
        forDate,
      );
    }
    return total;
  }

  private static costCenterDailyQueue = new Array<{
    group: string,
    offsetDay: number,
    startDateGantt: Date,
  }>();

  private static costCenterWeeklyQueue = new Array<{
    group: string,
    offsetWeek: number,
    startDateGantt: Date,
  }>();

  private static costcenterRoutineDaily = setInterval(() => {
    const nextQueue = this.costCenterDailyQueue[0];
    if (nextQueue === undefined) return;
    CostCenterHelper.costCenterDailyQueue.splice(0, 1);
    const colDate = DateHelper.addDaysWithoutWeekEnd(nextQueue.startDateGantt, nextQueue.offsetDay);

    // If cost center is a groupped one
    if (Object.keys(CostCenter.regroupedGroupId).includes(nextQueue.group)) {
      AffaireStore().costCenterDay[`${nextQueue.group}-${nextQueue.offsetDay}`] = CostCenterHelper.getHoursInDayByGroupGroupedRouting(CostCenter.regroupedGroupId[nextQueue.group], colDate);
    } else {
      CostCenterHelper.getHoursInDayByGroup(nextQueue.group, colDate).then((value) => {
        AffaireStore().costCenterDay[`${nextQueue.group}-${nextQueue.offsetDay}`] = value;
      });
    }
  }, 0);

  private static costcenterRoutineWeekly = setInterval(() => {
    const nextQueue = this.costCenterWeeklyQueue[0];
    if (nextQueue === undefined) return;
    CostCenterHelper.costCenterWeeklyQueue.splice(0, 1);
    const colDate = DateHelper
      .addDaysWithoutWeekEnd(nextQueue.startDateGantt, nextQueue.offsetWeek * 5);
    // If cost center is a groupped one
    if (Object.keys(CostCenter.regroupedGroupId).includes(nextQueue.group)) {
      AffaireStore().costCenterWeek[`${nextQueue.group}-${nextQueue.offsetWeek}`] = CostCenterHelper.getHoursInWeekByGroupGroupedRouting(CostCenter.regroupedGroupId[nextQueue.group], colDate);
    } else {
      CostCenterHelper.getHoursInWeekByGroup(nextQueue.group, colDate)
        .then((value) => {
          AffaireStore().costCenterWeek[`${nextQueue.group}-${nextQueue.offsetWeek}`] = value;
        });
    }
  }, 0);

  public static recalculateCostCenterDaily(weekToShow: number, startDateGantt: Date) {
    AffaireStore().costCenterDay = {};
    CostCenterHelper.costCenterDailyQueue = [];
    CostCenterHelper.getCostCenterToShowGroups().forEach((group) => {
      for (let i = 0; i < weekToShow * 5; i += 1) {
        CostCenterHelper.addToQueueCostCenterDaily(
          group,
          i,
          startDateGantt,
        );
      }
    });
  }

  public static recalculateCostCenterWeekly(weekToShow: number, startDateGantt: Date) {
    AffaireStore().costCenterWeek = {};
    CostCenterHelper.costCenterWeeklyQueue = [];
    CostCenterHelper.getCostCenterToShowGroups().forEach((group) => {
      for (let i = 0; i < weekToShow; i += 1) {
        CostCenterHelper.addToQueueCostCenterWeekly(
          group,
          i,
          startDateGantt,
        );
      }
    });
  }

  public static addToQueueCostCenterDaily(group: string, offsetDay: number, startDateGantt: Date) {
    CostCenterHelper.costCenterDailyQueue.push({
      group,
      offsetDay,
      startDateGantt,
    });
  }

  public static addToQueueCostCenterWeekly(
    group: string,
    offsetWeek: number,
    startDateGantt: Date,
  ) {
    CostCenterHelper.costCenterWeeklyQueue.push({
      group,
      offsetWeek,
      startDateGantt,
    });
  }

  public static getNumberDateOffsetCostCenterDaily(startDateGantt: Date, date: Date): number {
    return DateHelper.getBusinessDatesCount(startDateGantt, date) - 1;
  }

  private static getRoutingInRankByGroup(rank: AffaireRank, group: string): Array<RankRouting> {
    const result = new Array<RankRouting>();
    result.push(...rank.Postes.filter((p) => p.CostCenterObject?.Group === group));

    // Recursivity
    rank.Childs.forEach((r) => {
      result.push(...CostCenterHelper.getRoutingInRankByGroup(r, group));
    });
    return result;
  }

  public static getRoutingInJobByGroup(job: Affaire, group: string): Array<RankRouting> {
    const result = new Array<RankRouting>();

    job.Ranks.forEach((r) => {
      result.push(...CostCenterHelper.getRoutingInRankByGroup(r, group));
    });

    return result;
  }

  public static getColorFromGroup(group: string) {
    switch (group) {
      case 'CONTROLE':
        return 'zinc';
      case 'DEBIT/PERCAGE':
        return 'lime';
      case 'GRAVAGE':
        return 'orange';
      case 'LASER':
        return 'red';
      case 'MAINTENANCE':
        return 'emerald';
      case 'MONTAGE DAS-B':
        return 'cyan';
      case 'MONTAGE DAS-C':
        return 'sky';
      case 'PLIAGE':
        return 'violet';
      case 'SOUDAGE ACIER':
        return 'purple';
      case 'SOUDAGE ALU':
        return 'fuchsia';
      case 'SOUDAGE FSW':
        return 'pink';
      case 'SOUDAGE INOX':
        return 'rose';
      case 'SOUDAGE ROBOT':
        return 'blue';
      case 'USINAGE':
        return 'teal';
      default:
        return '';
    }
  }

  public static getMinDateInRankByCostCenterGroup(rank: AffaireRank, group: string)
    : Date | undefined {
    let minDate = undefined as undefined | Date;

    rank.getRoutingsToShow()
      .filter((r) => r.CostCenterObject !== undefined
        && r.CostCenterObject.Group !== undefined
        && r.CostCenterObject.Group === group)
      .forEach((r) => {
        if (minDate === undefined) {
          minDate = r.getStartDate();
        } else {
          minDate = DateHelper.min(r.getStartDate(), minDate);
        }
      });

    // Recursivity
    rank.Childs.forEach((r) => {
      const testDate = CostCenterHelper.getMinDateInRankByCostCenterGroup(r, group);
      if (testDate === undefined) return;
      if (minDate === undefined) {
        minDate = testDate;
      } else {
        minDate = DateHelper.min(testDate, minDate);
      }
    });
    return minDate;
  }

  public static getMinDateInJobByCostCenterGroup(affaire: Affaire, group: string)
    : Date | undefined {
    let minDate = undefined as undefined | Date;

    if (Object.keys(CostCenter.regroupedGroupId).includes(group)) {
      const regroupedRouting = affaire.getGroupRoutingsToShow()
        .find((rr) => rr.regroupId === CostCenter.regroupedGroupId[group]);
      if (regroupedRouting === undefined) {
        return undefined;
      }

      return regroupedRouting.getStartDate();
    }

    affaire.Ranks.forEach((r) => {
      const testDate = CostCenterHelper.getMinDateInRankByCostCenterGroup(r, group);
      if (testDate === undefined) return;
      if (minDate === undefined) {
        minDate = testDate;
      } else {
        minDate = DateHelper.min(minDate, testDate);
      }
    });

    return minDate;
  }

  public static getMaxDateInRankByCostCenterGroup(rank: AffaireRank, group: string)
    : Date | undefined {
    let maxDate = undefined as undefined | Date;

    rank.getRoutingsToShow()
      .filter((r) => r.CostCenterObject !== undefined
        && r.CostCenterObject.Group !== undefined
        && r.CostCenterObject.Group === group)
      .forEach((r) => {
        if (maxDate === undefined) {
          maxDate = r.getEndDate();
        } else {
          maxDate = DateHelper.max(r.getEndDate(), maxDate);
        }
      });

    // Recursivity
    rank.Childs.forEach((r) => {
      const testDate = CostCenterHelper.getMaxDateInRankByCostCenterGroup(r, group);
      if (testDate === undefined) return;
      if (maxDate === undefined) {
        maxDate = testDate;
      } else {
        maxDate = DateHelper.max(testDate, maxDate);
      }
    });
    return maxDate;
  }

  public static getMaxDateInJobByCostCenterGroup(affaire: Affaire, group: string)
    : Date | undefined {
    let maxDate = undefined as undefined | Date;

    if (Object.keys(CostCenter.regroupedGroupId).includes(group)) {
      const regroupedRouting = affaire.getGroupRoutingsToShow()
        .find((rr) => rr.regroupId === CostCenter.regroupedGroupId[group]);
      if (regroupedRouting === undefined) {
        return undefined;
      }

      return regroupedRouting.getEndDate();
    }

    affaire.Ranks.forEach((r) => {
      const testDate = CostCenterHelper.getMaxDateInRankByCostCenterGroup(r, group);
      if (testDate === undefined) return;
      if (maxDate === undefined) {
        maxDate = testDate;
      } else {
        maxDate = DateHelper.max(maxDate, testDate);
      }
    });

    return maxDate;
  }

  private static getCostCenterGroupInRankRecursive(rank: AffaireRank) : Set<string> {
    const result = new Array<string>();
    rank.Postes.forEach((p) => {
      if (p.CostCenterObject?.Group !== undefined) {
        result.push(p.CostCenterObject.Group);
      }
    });

    rank.Childs.forEach((r) => {
      result.push(...CostCenterHelper.getCostCenterGroupInRankRecursive(r));
    });
    return new Set<string>(result);
  }

  public static getCostCenterGroupInJob(affaire: Affaire) : Array<string> {
    const result = new Array<string>();

    affaire.Ranks.forEach((rank) => {
      result.push(...CostCenterHelper.getCostCenterGroupInRankRecursive(rank));
    });
    return [...new Set(result)];
  }
}
export default CostCenterHelper;
