import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Notification from '@/helper/Notification';
import UserAuthManager from '@/class/UserAuthManager';
import UserAuthStore from '@/store/UserAuthStore';
import Login from '../views/Login.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    alias: ['/login'],
    component: Login,
    beforeEnter(to, from, next) {
      /*
      if (UserAuthManager.isLoggedIn()) {
        next({ name: from.name || localStorage.getItem('lastRoute') || 'Login' });
        return;
      }
      */
      next();
    },
  },
  {
    path: '/backoffice',
    name: 'BackOffice',
    component: () => import(/* webpackChunkName: "backoffice" */ '@/views/BackOffice.vue'),
  },
  {
    path: '/operateur',
    name: 'Operateur',
    component: () => import(/* webpackChunkName: "operateur" */ '@/views/Operateur.vue'),
  },
  {
    path: '/planificateur',
    name: 'Planificateur',
    component: () => import(/* webpackChunkName: "planificateur" */ '@/views/Planificateur.vue'),
  },
  {
    path: '/Coordinateur',
    name: 'Coordinateur',
    component: () => import(/* webpackChunkName: "coordinateur" */ '@/views/Coordinateur.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: () => {
      UserAuthManager.handleLogout();
      return { name: 'Login' };
    },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// From the doc, make sure next() is called once .
router.beforeEach((to, from, next) => {
  const userAuthStore = UserAuthStore();
  if (to.name !== 'Login' && userAuthStore.user === undefined) {
    Notification.notifyWarning('Vous devez être connecté pour accèder à cette page.');
    next({ name: 'Login' });
  } else {
    next();
  }
});

// Save the last route to the local storage
router.beforeEach((to, from, next) => {
  if (UserAuthManager.isLoggedIn() && to.name !== 'Login') {
    localStorage.setItem('lastRoute', typeof to.name === 'string' ? to.name : 'Login');
  }
  next();
});

export default router;
