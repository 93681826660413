import Serializable from '@/helper/SerializationHelper';
import DateHelper from '@/helper/DateHelper';
import Bom from '@/class/Bom';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import Notification from '@/helper/Notification';
import MainEndpoint from '@/endpoints/main';
import Affaire from '@/class/Affaire';
import AffaireRank from '@/class/AffaireRank';
import GanttStore from '@/store/GanttStore';
import lodash from 'lodash';
import RankClockingState from '@/enum/RankClockingState';
import CostCenter from '@/class/CostCenter';
import moment from 'moment';
import RankOverdueState from '@/enum/RankOverdueState';
import RoutingDayPlanning from '@/class/RoutingDayPlanning';

abstract class BaseRouting extends Serializable {
  public startDate: Date = DateHelper.setToMonday(new Date());

  public endDate: Date = DateHelper.setToFriday(new Date());

  public IdRouting = 0;

  public Boms = new Array<Bom>();

  public Affaire = undefined as undefined | Affaire;

  public routingsWeekPlanning = new Array<RoutingWeekPlanning>();

  public ManuTime = 0;

  public weeksHoursDistributor = new Array<number>();

  public Rank = undefined as undefined | AffaireRank;

  public CostCenterObject: CostCenter | undefined = undefined;

  public routingStatusList = [] as Array<[number, RankOverdueState]>;

  public isReplanned = false;

  abstract moveDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
    checkRoutingOnTop: boolean,
  ): number;

  abstract handleRouting(
    maxDate: Date,
    minDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
    checkRoutingOnTop: boolean,
  ): void;

  abstract moveEndDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
  ): void;

  abstract moveStartDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
  ): void;

  public getStartDate() : Date {
    return this.startDate;
  }

  public getEndDate() : Date {
    return DateHelper.setToFriday(this.endDate);
  }

  public persistDate(groupedId : undefined | number = undefined): void {
    if (this.Affaire === undefined) {
      Notification.notifyWarning('Une erreur est apparu lors de la sauvegarde du Gantt - Le routing n\'est pas affecté à une affaire');
      return;
    }
    MainEndpoint.getAxiosInstance().post(`/affaire/routing/update/${this.IdRouting}`, {
      startDate: this.startDate.toUTCString(),
      endDate: DateHelper.setToFriday(new Date(this.endDate.toUTCString())),
      job: this.Affaire.Job,
      weeksHoursDistributor: JSON.stringify(this.weeksHoursDistributor),
      groupedId,
    }).catch((reason) => {
      Notification.notifyWarning(`Une erreur est apparu lors de la sauvegarde du Gantt - ${reason}`);
    });
  }

  public getCurrentWeekMinutesDefined() {
    let total = 0;
    this.routingsWeekPlanning.forEach((wr: RoutingWeekPlanning) => {
      wr.routingsDayPlanning.forEach((rdp: RoutingDayPlanning) => {
        if (!rdp.isNotFinishReplaned) {
          total += rdp.minutes;
        }
      });
    });
    return total;
  }

  public recalculateWeeksHoursDistributor(hoursToFill: number | undefined = undefined): void {
    const hoursOperatorWeek = parseInt(process.env.VUE_APP_WEEK_OPERATOR_WORK_TIME, 10);
    const numberOfWeeks = DateHelper.getWeekDatesCount(this.getStartDate(), this.getEndDate());
    let hoursToFillLeft = (hoursToFill === undefined ? this.ManuTime : hoursToFill);

    this.weeksHoursDistributor = [];
    for (let i = 0; i < numberOfWeeks; i += 1) {
      if (i + 1 === numberOfWeeks) {
        // Last week
        this.weeksHoursDistributor.push(
          Math.round(
            Math.max(0, hoursToFillLeft) * 100,
          ) / 100,
        );
      } else {
        const hoursToAdd = Math.round(Math.min(hoursOperatorWeek, hoursToFillLeft) * 100) / 100;
        this.weeksHoursDistributor.push(hoursToAdd);
        hoursToFillLeft -= hoursToAdd;
      }
    }
  }

  public getWeeksHoursDistributor() {
    return this.weeksHoursDistributor;
  }

  public isShowSubTask(isGanttValueTask = false) {
    const ganttStore = GanttStore();
    let weekFilterStatus = false;
    let costCenterStatus = false;

    if (lodash.isEmpty(ganttStore.weeksFilter) && lodash.isEmpty(ganttStore.costCenterGroupFilter)) {
      return true;
    }

    if (!lodash.isEmpty(ganttStore.weeksFilter)) {
      lodash.some(ganttStore.weeksFilter, (dateTest) => {
        const friday = lodash.chain(new Date(dateTest))
          .thru((date) => DateHelper.setToFriday(date))
          // eslint-disable-next-line @typescript-eslint/no-shadow
          .thru((friday) => {
            friday.setHours(23, 59, 59, 0);
            return friday;
          })
          .value();

        const startDateTimestamp = this.startDate.getTime();
        const endDateTimestamp = this.endDate.getTime();

        if (lodash.some([startDateTimestamp, endDateTimestamp], (date) => DateHelper.isDateInRange(new Date(date), dateTest, friday))) {
          weekFilterStatus = true;
        }

        if (
          lodash.inRange(startDateTimestamp, dateTest.getTime(), friday.getTime())
          || lodash.inRange(endDateTimestamp, dateTest.getTime(), friday.getTime())
        ) {
          weekFilterStatus = true;
        }
      });
    } else {
      weekFilterStatus = true;
    }

    if (!lodash.isEmpty(ganttStore.costCenterGroupFilter) && !lodash.isUndefined(this.Affaire)) {
      if (isGanttValueTask) {
        const costCenterIdGroup = this.Affaire.Ranks[0].getCostCenterByIdRouting(this.IdRouting);
        if (!lodash.isUndefined(costCenterIdGroup) && lodash.includes(ganttStore.costCenterGroupFilter, costCenterIdGroup)) {
          costCenterStatus = true;
          return costCenterStatus && weekFilterStatus;
        }
      } else {
        for (let i = 0; i < ganttStore.costCenterGroupFilter.length; i += 1) {
          if (lodash.includes(this.Affaire.Ranks[0].ChildsCostCenter, ganttStore.costCenterGroupFilter[i])) {
            costCenterStatus = true;
            return costCenterStatus && weekFilterStatus;
          }
        }
      }
    } else {
      costCenterStatus = true;
    }

    return weekFilterStatus && costCenterStatus;
  }

  public getStatusTask() {
    let isFinished = false;

    for (let i = 0; i < this.routingsWeekPlanning.length; i += 1) {
      const rwp = this.routingsWeekPlanning[i];
      for (let j = 0; j < rwp.routingsDayPlanning.length; j += 1) {
        const rdp = rwp.routingsDayPlanning[j];
        if (rdp.isCurrentlyDoing) {
          return RankClockingState.IN_PROGRESS;
        }

        if (rdp.isNotFinishReplaned && this.isReplanned) {
          return RankClockingState.REPLANNED;
        }

        if (rdp.totalTimeClocking > 0 && !rdp.isFinished) {
          return RankClockingState.STARTED;
        }

        if (rdp.isFinished) {
          isFinished = true;
        }
      }
    }

    if (isFinished) {
      return RankClockingState.FINISHED;
    }

    return RankClockingState.NOT_STARTED;
  }

  public getLatStatusTask() {
    this.routingStatusList = [];
    const currentDate = moment().add(1, 'week');

    if (this.routingsWeekPlanning.length === 0) {
      // this.routingStatusList.push([this.IdRouting, RankOverdueState.NOT_ROUTING]);
    }

    for (let i = 0; i < this.routingsWeekPlanning.length; i += 1) {
      const rwp = this.routingsWeekPlanning[i];
      for (let j = 0; j < rwp.routingsDayPlanning.length; j += 1) {
        const rdp = rwp.routingsDayPlanning[j];
        const endPostDate = moment(rdp.startDate);

        // Routing don't start
        if (currentDate.isAfter(endPostDate) && rdp.totalTimeClocking <= 0 && !rdp.isCurrentlyDoing && !rdp.isFinished) {
          this.routingStatusList.push([this.IdRouting, RankOverdueState.NOT_START]);
        }

        // Routing don't finish with time  inferior to time defined
        if (currentDate.isAfter(endPostDate) && rdp.totalTimeClocking >= 1 && rdp.totalTimeClocking < rdp.minutes) {
          this.routingStatusList.push([this.IdRouting, RankOverdueState.NOT_FINISH_TIME_INFERIOR]);
        }

        // Routing don't finish with time  superior to time defined
        if (currentDate.isAfter(endPostDate) && rdp.totalTimeClocking >= 1 && rdp.totalTimeClocking > rdp.minutes) {
          this.routingStatusList.push([this.IdRouting, RankOverdueState.NOT_FINISH_TIME_SUPERRIOR]);
        }
      }
    }

    return this.routingStatusList;
  }

  public getCostCenterName() {
    return this.CostCenterObject?.Group || '';
  }

  public setIsReplanned(isReplanned: boolean) {
    this.isReplanned = isReplanned;
    const IdJobFile = this.Rank?.IdJobFile;
    if (IdJobFile === undefined) {
      return;
    }

    MainEndpoint.getAxiosInstance().post(`/rank/${this.IdRouting}/set-is-replanned`, {
      isReplanned,
      IdJobFile,
    }).catch((reason) => {
      Notification.notifyWarning(`Une erreur est apparu lors de la sauvegarde du Gantt - ${reason}`);
    });
  }
}

export default BaseRouting;
