import Serializable from '@/helper/SerializationHelper';
import PurchaseOrder from '@/class/PurchaseOrder';
import BomStatut from '@/enum/BomStatut';
import RankRouting from '@/class/RankRouting';
import ProductionReservation from '@/class/ProductionReservation';
import Reservation from '@/class/Reservation';
import BomAlert from '@/class/BomAlert';
import moment from 'moment';
import SubTraintenceProblem from '@/enum/SubTraintenceProblem';

class Bom extends Serializable {
  public ConstantPrice = 0;

  public Deadline = new Date();

  public Designation1 = '';

  public Designation2 = '';

  public Designation3 = '';

  public Factor = 0;

  public Family = '';

  public IdBom = 0;

  public IdJobFile = 0;

  public Item = '';

  public Job = 0;

  public LandMark = '';

  public Phase = 0;

  public Price = 0;

  public PriceFactor = 0;

  public Qty = 0;

  public Rank = '';

  public Supplier1 = '';

  public Supplier2 = '';

  public SupplyTime = 0;

  public Unit = '';

  public UnitFactor = 0;

  public UnitPrice = '';

  public Routing : RankRouting;

  public PurchaseOrders: any[] = [];

  public ProductionReservations: any[] = [];

  public Reservations: any[] = [];

  public PurchaseOrdersObject = new Array<PurchaseOrder>();

  public ProductionReservationsObject = new Array<ProductionReservation>();

  public ReservationsObject = new Array<Reservation>();

  public listStatusSousTraitance: any[] = [];

  constructor(routing: RankRouting) {
    super();
    this.Routing = routing;
  }

  public fillFromJSON(data: any) {
    super.fillFromJSON(data);
    this.Deadline = new Date(data.Deadline);

    this.PurchaseOrders.forEach((po) => {
      const purchaseOrder = new PurchaseOrder();
      purchaseOrder.fillFromJSON(po);
      this.PurchaseOrdersObject.push(purchaseOrder);
    });

    this.ProductionReservations.forEach((pr) => {
      const productionReservation = new ProductionReservation();
      productionReservation.fillFromJSON(pr);
      this.ProductionReservationsObject.push(productionReservation);
    });

    this.Reservations.forEach((r) => {
      const reservation = new Reservation();
      reservation.fillFromJSON(r);
      this.ReservationsObject.push(reservation);
    });
  }

  public getStatut(): Array<BomStatut> {
    const status = new Array<BomStatut>();

    // Pas de la sous traitance
    if (this.Family !== 'STTS') {
      // console.log(this);
      // Aucune reservation
      if (this.ReservationsObject.length === 0
        && this.ProductionReservationsObject.length === 0) {
        status.push(BomStatut.NOT_BOOKED);
      } else {
        status.push(BomStatut.BOOKED);
      }
      if (this.PurchaseOrdersObject.length !== 0) {
        const totalOrdered = this.PurchaseOrdersObject
          .map((x) => x.Qty)
          .reduce((previous, current) => previous + current, 0);
        if (totalOrdered >= this.Qty) {
          status.push(BomStatut.PARTIALLY_ORDERED);
        } else {
          const findOtherRef = this.PurchaseOrdersObject
            .filter((po) => po.Product !== this.Item).length !== 0;
          if (findOtherRef) {
            status.push(BomStatut.ORDERED_OTHER_REFERENCE);
          } else {
            status.push(BomStatut.ORDERED);
          }
        }

        let totalReceived = 0;
        this.PurchaseOrdersObject.forEach((po) => {
          po.SupplierDeliveryNotesObject.forEach((sdn) => {
            totalReceived += sdn.Qty;
          });
        });
        if (totalReceived > 0) {
          if (totalReceived >= totalOrdered) {
            status.push(BomStatut.RECEIVED);
          } else {
            status.push(BomStatut.PARTIALLY_RECEIVED);
          }
        }
      } else if (this.PurchaseOrdersObject.length === 0 && this.ProductionReservations.length > 0) {
        status.push(BomStatut.PRODUCTION);

        const totalProduct = this.ProductionReservations
          .map((x) => x.Qty)
          .reduce((previous, current) => previous + current, 0);

        if (totalProduct === this.Qty) {
          status.push(BomStatut.PRODUCTION_DONE);
        } else {
          status.push(BomStatut.PRODUCTION_PARTIALLY);
        }
      } else if (this.Reservations.length > 0 && this.ReservationsObject.length > 0) {
        status.push(BomStatut.BOOKED_STOCK);
      } else {
        // console.log(this);
        status.push(BomStatut.NO_ORDER);
      }
    // Sous traitance
    } else if (this.PurchaseOrdersObject.length === 0) {
      status.push(BomStatut.ST_NO_ORDER);
      // Retard délai de 21 jours
      if (this.Routing.startDate.getTime() - new Date().getTime() < 21 * 24 * 60 * 60 * 1000) {
        status.push(BomStatut.ST_NO_ORDER_DELAY);
      }
    } else {
      const totalOrdered = this.PurchaseOrdersObject
        .map((x) => x.Qty)
        .reduce((previous, current) => previous + current, 0);
      status.push(BomStatut.ST_ORDER_DONE);

      let totalReceived = 0;
      this.PurchaseOrdersObject.forEach((po) => {
        po.SupplierDeliveryNotesObject.forEach((sdn) => {
          totalReceived += sdn.Qty;
        });
      });
      if (totalReceived === 0) {
        status.push(BomStatut.ST_PIECES_SENT);
      } else if (totalReceived >= totalOrdered) {
        status.push(BomStatut.ST_PIECES_RECEIVED);
      } else {
        status.push(BomStatut.ST_PIECES_RECEIVED_PARTIALLY);
      }
    }

    if (status.length === 0) {
      status.push(BomStatut.UNDEFINED);
    }
    return status;
  }

  public getAlerts(): Array<BomAlert> {
    const alerts = new Array<BomAlert>();
    const status = this.getStatut();
    if (!status.includes(BomStatut.RECEIVED) && !status.includes(BomStatut.ST_PIECES_RECEIVED)) {
      if (this.Deadline > new Date()) {
        const alert = new BomAlert(this);
        alert.setMessage('Cette nomemclature n\'a pas été reçu et le délai est dépassé.');
        alert.setBackgroundColor('bg-red-500');
        alert.setTextColor('text-white');
        alerts.push(
          alert,
        );
      }
    }
    return alerts;
  }

  public getLateStatusBom(maxTime: Date) {
    this.PurchaseOrders.forEach((purchaseOrder: PurchaseOrder) => {
      const oldDate = moment(maxTime);
      const newDate = moment(purchaseOrder.Date);
      const diff = oldDate.diff(newDate, 'weeks');

      if (purchaseOrder.SupplierDeliveryNotes.length <= 0) {
      //  [ ] Si la sous-traitance n’a pas été envoyée à la date prévue.
        this.addListStatusSousTraitance(purchaseOrder, SubTraintenceProblem.NOT_SEND, diff);
      } else if (purchaseOrder.SupplierDeliveryNotes[0].Date > maxTime) {
        this.addListStatusSousTraitance(purchaseOrder, SubTraintenceProblem.NOT_SEND, diff);
      } else if (!moment(purchaseOrder.Date).isSame(maxTime)) {
        //  [ ] Si la date de livraison (date du bon de commande founiseur) est différente que la date de fin de la sous-traitance.
        this.addListStatusSousTraitance(purchaseOrder, SubTraintenceProblem.DATE_NOT_MATCH, diff);
      }
    });
  }

  public addListStatusSousTraitance(purchaseOrder: PurchaseOrder, problem: SubTraintenceProblem, diff: number): void {
    const doubles = this.listStatusSousTraitance.find((item) => item[1] === this.IdBom);
    if (doubles === undefined) {
      this.listStatusSousTraitance.push([this.Routing.IdRouting, purchaseOrder.Ordernr, purchaseOrder.Supplier, problem, diff]);
    }
  }
}
export default Bom;
