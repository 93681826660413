import AffaireRank from '@/class/AffaireRank';
import DateHelper from '@/helper/DateHelper';
import AffaireBase from '@/class/AffaireBase';
import CostCenter from '@/class/CostCenter';
import GroupedRouting from '@/class/GroupedRouting';
import CostCenterHelper from '@/helper/CostCenterHelper';
import BomHelper from '@/helper/BomHelper';
import GanttStore from '@/store/GanttStore';
import RankRouting from '@/class/RankRouting';
import lodash from 'lodash';
import AssociateAffaire from '@/class/AssociateAffaire';
import MainEndpoint from '@/endpoints/main';
import Notiflix from 'notiflix';
import BomGroupStatus from '@/enum/BomGroupStatus';

class Affaire extends AffaireBase {
  public Ranks = Array<AffaireRank>();

  public showRanksInGantt = false;

  public regroupedRoutings: Array<GroupedRouting> = new Array<GroupedRouting>();

  public associateTaskList = [] as Array<AssociateAffaire>;

  public delayNomenclatureTaskList = [] as Array<any>;

  public delaySousTraitanceTaskList = [] as Array<any>;

  public delayRoutingTaskList = [] as Array<any>;

  fillFromJSON(data: any): void {
    super.fillFromJSON(data);
    const ranks = Array<AffaireRank>();
    this.Ranks.forEach((r) => {
      const classRank = new AffaireRank(this);
      classRank.fillFromJSON(r);
      ranks.push(classRank);
      this.ChildsCostCenter = lodash.union(this.ChildsCostCenter, classRank.ChildsCostCenter);
    });
    this.Ranks = ranks;

    // Regrouped Routings
    const postes = Array<GroupedRouting>();
    (this.regroupedRoutings as unknown as any[]).forEach((r: any) => {
      const classRouting = new GroupedRouting(r.groupedId, this);
      classRouting.fillFromJSON(r);
      classRouting.startDate = new Date(r.startDate);
      classRouting.endDate = new Date(r.endDate);
      if (r.weeksHoursDistributor) postes.push(classRouting);
      if (!this.ChildsCostCenter.includes(classRouting.getGroup())) this.ChildsCostCenter.push(classRouting.getGroup());
      classRouting.Affaire = this;
      classRouting.weeksHoursDistributor = JSON.parse(r.weeksHoursDistributor);
      if (r.startDate === undefined || r.endDate === undefined) {
        classRouting.persistDate();
      }
    });
    this.regroupedRoutings = [];
    postes.forEach((poste) => {
      this.regroupedRoutings.push(poste);
    });
    if (this.regroupedRoutings.length === 0) {
      this.initRegroupedRoutings();
    }

    if (data.DealineWeb !== null) {
      this.InitDeadline = data.DealineWeb;
    } else {
      this.InitDeadline = data.InitDeadline;
    }

    if (data.isNew) {
      this.isNewAffaire = true;
    }
  }

  private initRegroupedRoutings() {
    Object.keys(CostCenter.regroupedGroupId).forEach((group) => {
      const regroupId: number = CostCenter.regroupedGroupId[group];

      const routingsOnGroup = CostCenterHelper.getRoutingInJobByGroup(this, group);
      if (routingsOnGroup.length === 0) return;
      const freeIdRouting = routingsOnGroup[0].IdRouting;

      const routing = new GroupedRouting(regroupId, this, routingsOnGroup);
      routing.Affaire = this;
      routing.IdRouting = freeIdRouting;
      this.regroupedRoutings.push(routing);
      routing.recalculateWeeksHoursDistributor();
      routing.persistDate();
    });
  }

  private static getMinDateInRankRecursive(rank: AffaireRank): Date | undefined {
    let testDate: undefined | Date;
    const ganttStore = GanttStore();

    rank.getRoutingsToShow()
      .filter((r) => r.CostCenterObject !== undefined
        && r.CostCenterObject.Group !== undefined
        && this.isGroupAllow(r.CostCenterObject.Group))
      .forEach((routing) => {
        if (testDate === undefined) {
          testDate = routing.startDate;
        } else {
          testDate = DateHelper.min(testDate, routing.startDate);
        }
      });

    rank.Childs.forEach((rankChild) => {
      const newDate = Affaire.getMinDateInRankRecursive(rankChild);
      if (newDate === undefined) return;
      if (testDate === undefined) {
        testDate = newDate;
      } else {
        testDate = DateHelper.min(newDate, testDate);
      }
    });
    return testDate;
  }

  public static isGroupAllow(group: string) {
    const ganttStore = GanttStore();

    if (ganttStore.costCenterGroupFilter.length === 0) return true;

    return ganttStore.costCenterGroupFilter.includes(group);
  }

  public getMinDateRouting(): Date | undefined {
    let testDate: undefined | Date;
    this.Ranks.forEach((rank) => {
      const newDate = Affaire.getMinDateInRankRecursive(rank);
      if (newDate === undefined) return;
      if (testDate === undefined) {
        testDate = newDate;
      } else {
        testDate = DateHelper.min(testDate, newDate);
      }
    });

    this.getGroupRoutingsToShow().forEach((rr) => {
      if (testDate === undefined) {
        testDate = rr.getStartDate();
      } else {
        testDate = DateHelper.min(rr.getStartDate(), testDate);
      }
    });
    return testDate === undefined ? undefined : new Date(testDate);
  }

  private static getMaxDateInRankRecursive(rank: AffaireRank): Date | undefined {
    let testDate: undefined | Date;
    const ganttStore = GanttStore();
    rank.getRoutingsToShow(true)
      .filter((r) => r.CostCenterObject !== undefined
        && r.CostCenterObject.Group !== undefined
        && ganttStore.costCenterGroupFilter.includes(r.CostCenterObject.Group))
      .forEach((routing) => {
        if (testDate === undefined) {
          testDate = routing.endDate;
        } else {
          testDate = DateHelper.max(testDate, routing.endDate);
        }
      });
    rank.Childs.forEach((rankChild) => {
      const newDate = Affaire.getMaxDateInRankRecursive(rankChild);
      if (newDate === undefined) return;
      if (testDate === undefined) {
        testDate = newDate;
      } else {
        testDate = DateHelper.max(newDate, testDate);
      }
    });
    return testDate;
  }

  public getMaxDateRouting(): Date | undefined {
    let testDate: undefined | Date;
    this.Ranks.forEach((rank) => {
      const newDate = Affaire.getMaxDateInRankRecursive(rank);
      if (newDate === undefined) return;
      if (testDate === undefined) {
        testDate = newDate;
      } else {
        testDate = DateHelper.max(newDate, testDate);
      }
    });

    this.getGroupRoutingsToShow().forEach((rr) => {
      if (testDate === undefined) {
        testDate = rr.getEndDate();
      } else {
        testDate = DateHelper.max(rr.getEndDate(), testDate);
      }
    });
    return testDate === undefined ? undefined : new Date(testDate);
  }

  public getStatusBom(): BomGroupStatus {
    let status = BomGroupStatus.RECEIVED;
    let newStatus = -1;
    this.Ranks.forEach((rank) => {
      newStatus = rank.getStatusBom();
      status = BomHelper.getStatusBom(status, newStatus);
    });
    return status;
  }

  public getGroupRoutingsToShow(): GroupedRouting[] {
    return lodash.filter(this.regroupedRoutings, (rr) => rr.isShowSubTask(true));
  }

  public getFilteredGroupRoutines() {
    const ganttStore = GanttStore();
    let routingInRange = false;
    let routingInGroup = false;

    if (lodash.size(ganttStore.weeksFilter) === 0) {
      routingInRange = true;
    } else {
      lodash.forEach(this.regroupedRoutings, (rr) => {
        ganttStore.weeksFilter.every((dateTest) => {
          const friday = DateHelper.setToFriday(new Date(dateTest));
          friday.setHours(23, 59, 59, 0);
          if (DateHelper.isDateInRange(rr.startDate, dateTest, friday)) {
            routingInRange = true;
            return false;
          }
          if (DateHelper.isDateInRange(rr.endDate, dateTest, friday)) {
            routingInRange = true;
            return false;
          }
          if (rr.startDate >= dateTest && rr.endDate <= friday) {
            routingInRange = true;
            return false;
          }

          if (rr.startDate <= dateTest && rr.endDate >= friday) {
            routingInRange = true;
            return false;
          }
          return true;
        });
        return true;
      });
    }

    if (lodash.size(ganttStore.costCenterGroupFilter) === 0) {
      routingInGroup = true;
    } else {
      lodash.forEach(this.regroupedRoutings, (rankRegroup) => {
        lodash.forEach(ganttStore.costCenterGroupFilter, (costCenterId) => {
          if (rankRegroup.getGroup() === costCenterId) {
            routingInGroup = true;
          }
        });
      });
    }

    return routingInRange && routingInGroup;
  }

  public isShowAffaire() {
    let isShow = false;
    const addRank = (rank: AffaireRank) => {
      if (isShow) return;
      isShow = (rank.getFilteredRoutings().length > 0);
      lodash.forEach(rank.Childs, (child) => {
        addRank(child);
      });
    };
    lodash.forEach(this.Ranks, (rank) => {
      addRank(rank);
    });
    return isShow;
  }

  public getRoutingById(idRouting: number) {
    let routingFound: GroupedRouting | RankRouting | undefined;
    this.Ranks.forEach((rank) => {
      const routingFoundInRank = rank.getRoutingById(idRouting);
      if (routingFoundInRank !== undefined) {
        routingFound = routingFoundInRank;
      }
    });

    if (routingFound === undefined) {
      routingFound = this.regroupedRoutings.find((rr) => rr.IdRouting === idRouting);
    }

    return routingFound;
  }

  public getIsForecasts() {
    return this.Job.toString().slice(0, 2) === '10' && this.Job.toString().length === 6;
  }

  public async addAssociateTaskList() {
    try {
      const response = await MainEndpoint.getAxiosInstance().get(`affaire/get-associate-job/${this.Job}`);
      const data = response.data;

      if (data.length > 0) {
        data.forEach((aff: any) => {
          const associateTask = new AssociateAffaire(aff.client, aff.job, aff.quantity);
          if (!this.associateTaskList.includes(associateTask)) {
            this.associateTaskList.push(associateTask);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  public setDeadline(deadline: string) {
    if (this.InitDeadline === deadline) return;
    this.InitDeadline = deadline;

    const data = {
      deadline: this.InitDeadline,
    };
    MainEndpoint.getAxiosInstance().post(`/affaire/deadline/update/${this.Job}`, data).catch(() => {
      Notiflix.Notify.failure('Deadline non enregistrée');
    });
  }

  public async getAssociateAffaires(): Promise<Array<number>> {
    try {
      const response = await MainEndpoint.getAxiosInstance().get(`affaire/get-associate/${this.Job}`);
      return response.data;
    } catch (e) {
      Notiflix.Notify.failure('Erreur lors de la récupération des affaires associées');
      return [];
    }
  }

  public setNomenclatureStatus() {
    this.delayNomenclatureTaskList = [];
    this.delaySousTraitanceTaskList = [];
    this.delayRoutingTaskList = [];

    this.Ranks.forEach((rank) => {
      rank.getNomonclatureStatus();

      if (rank.delayNomenclatureTaskList.length > 0) {
        this.delayNomenclatureTaskList.push([rank.Rank, rank.delayNomenclatureTaskList]);
      }

      if (rank.delaySousTraitanceTaskList.length > 0) {
        this.delaySousTraitanceTaskList.push([rank.Rank, rank.delaySousTraitanceTaskList]);
      }
    });

    this.Ranks.forEach((rank) => {
      const taskDelays = rank.getStateLateClocking();
      if (taskDelays.length > 0) {
        this.delayRoutingTaskList.push([rank.Rank, taskDelays]);
      }
    });
  }

  public IsOverDelays() {
    return this.delayNomenclatureTaskList.length > 0 || this.delayRoutingTaskList.length > 0;
  }

  public IsSousTraitanceOverDelays() {
    return this.delaySousTraitanceTaskList.length > 0;
  }
}
export default Affaire;
