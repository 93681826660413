import Serializable from '@/helper/SerializationHelper';

class UserEntity extends Serializable {
  public User = '';

  public Name = '';

  public FirstName = '';

  public Department = '';

  public ReleaseDate = '';

  public ReleaseDateAsDate = undefined as undefined | Date;

  fillFromJSON(data: any) {
    super.fillFromJSON(data);
    this.ReleaseDateAsDate = new Date(this.ReleaseDate);
  }
}

export default UserEntity;
