import Serializable from '@/helper/SerializationHelper';

class User extends Serializable {
  public firstname = '';

  public lastname = '';

  public role = '';

  public username = '';
}

export default User;
