import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 overflow-hidden z-20 pointer-events-none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_ctx.env !== 'PROD')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showWhatsNew && _ctx.showWhatsNew(...args))),
            class: "absolute -bottom-1 -right-1 p-2 rounded-tl-xl pointer-events-auto bg-trac-blue text-white font-bold cursor-pointer"
          }, _toDisplayString(_ctx.env) + " - v." + _toDisplayString(_ctx.version), 33)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}