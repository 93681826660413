import Notiflix from 'notiflix';

class Notify {
  public static notifyFailure(message: string): void {
    Notiflix.Notify.failure(message);
  }

  public static notifySuccess(message: string): void {
    Notiflix.Notify.success(message);
  }

  public static notifyWarning(message: string): void {
    Notiflix.Notify.warning(message);
  }

  public static notifyInfo(message: string): void {
    Notiflix.Notify.info(message);
  }
}

export default Notify;
