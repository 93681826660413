import UserEntity from '@/class/UserEntity';
import MainEndpoint from '@/endpoints/main';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import { AxiosError } from 'axios';
import Notify from '@/helper/Notification';
import Serializable from '@/helper/SerializationHelper';

class RoutingDayPlanning extends Serializable {
  public weekPlanning: RoutingWeekPlanning;

  public minutes = 0;

  public operator: UserEntity;

  public startDate: Date;

  public internalId = undefined as undefined | number;

  public isCurrentlyDoing = false;

  public startDateClocking = undefined as undefined | Date;

  public isNotFinishReplaned = false;

  /**
   * Le temps passé de pointage en seconde !
   */
  public totalTimeClocking = 0;

  public isFinished = false;

  constructor(weekPlanning: RoutingWeekPlanning, operator: UserEntity, startDate: Date) {
    super();
    this.weekPlanning = weekPlanning;
    this.operator = operator;
    this.startDate = startDate;
  }

  public fillFromJSON(data: any) {
    super.fillFromJSON(data);

    if (data.startDateClocking !== null) {
      this.startDateClocking = new Date(this.startDateClocking as Date);
    } else {
      this.startDateClocking = undefined;
    }
  }

  public save(clb: undefined | (() => void) = undefined, clipperUpdate = true) {
    if (this.weekPlanning.internalId === undefined) return;

    const data = {
      date: this.startDate.toUTCString(),
      minutes: this.minutes,
      idWeekPlanning: this.weekPlanning.internalId,
      operator: this.operator.User,

      isCurrentlyDoing: this.isCurrentlyDoing,
      startDateClocking: this.startDateClocking?.toUTCString() ?? null,
      totalTimeClocking: this.totalTimeClocking,
      isFinished: this.isFinished,
      isNotFinishReplaned: this.isNotFinishReplaned,
      clipperUpdate,
    };
    if (this.internalId !== undefined) {
      Object.assign(data, { internalId: this.internalId });
    }

    MainEndpoint.getAxiosInstance()
      .post('/day-planning/save', data)
      .then((response) => {
        if (this.internalId === undefined) {
          this.internalId = response.data.internalId;
        }
        if (clb !== undefined) clb();
      }).catch((error: AxiosError) => {
        Notify.notifyFailure(`Impossible de sauvegarder la programmation. ${error.response?.data.error}`);
      });
  }

  public deleteToApi(clb: undefined | (() => void) = undefined) {
    if (this.internalId === undefined) return;
    MainEndpoint.getAxiosInstance()
      .delete(`/day-planning/remove/${this.internalId}`)
      .then(() => {
        this.internalId = undefined;
        if (clb !== undefined) clb();
      });
  }

  public getEndDate() {
    return new Date(this.startDate.getTime() + this.minutes * 60000);
  }

  public startClocking() {
    if (this.isFinished) {
      throw new Error('Cannot start clocking if the task is marked as finished');
    }
    this.isCurrentlyDoing = true;
    this.startDateClocking = new Date();

    this.save();
  }

  public updateChildNotFinished() {
    const ownRank = this.weekPlanning.baseRouting.Rank;
    ownRank?.Childs.forEach((rank) => {
      rank.Postes.forEach((poste) => {
        poste.routingsWeekPlanning.forEach((rwp) => {
          rwp.routingsDayPlanning.forEach((rdp) => {
            if (rdp.totalTimeClocking > 0) {
              // eslint-disable-next-line no-param-reassign
              rdp.isFinished = true;
              rdp.save(undefined, false);
            }
          });
        });
      });
    });
  }

  public pauseClocking(save = true) {
    if (this.isFinished) {
      throw new Error('Cannot pause clocking if the task is marked as finished');
    }
    if (this.startDateClocking === undefined) {
      return;
    }

    // Add seconds elapsed
    this.totalTimeClocking += (Date.now() / 1000) - (this.startDateClocking.getTime() / 1000);

    this.isCurrentlyDoing = false;
    this.startDateClocking = undefined;

    if (save) {
      this.save();
    }
  }

  public finishClocking() {
    if (this.isFinished) {
      throw new Error('Cannot finish clocking if the task is marked as finished');
    }
    this.pauseClocking(false);

    this.isFinished = true;
    this.save();
  }

  public setIsFinished(isFinished: boolean) {
    if (isFinished) {
      if (!this.isFinished) {
        this.finishClocking();
      }
    } else {
      this.isFinished = false;
      this.save();
    }
  }

  public setMinutes(minutes: number) {
    this.minutes = minutes;
  }

  public setIsNotFinishReplaned(isNotFinishReplaned: boolean) {
    this.isNotFinishReplaned = isNotFinishReplaned;
    MainEndpoint.getAxiosInstance().post('day-planning/set-replanned-task', {
      internalId: this.internalId,
      isNotFinishReplaned,
    }).catch((error: AxiosError) => {
      Notify.notifyFailure(`Impossible de sauvegarder la programmation. ${error.response?.data.error}`);
    });
  }
}

export default RoutingDayPlanning;
