import BaseRouting from '@/class/BaseRouting';
import CostCenterHelper from '@/helper/CostCenterHelper';
import CostCenter from '@/class/CostCenter';
import RankRouting from '@/class/RankRouting';
import Affaire from '@/class/Affaire';
import DateHelper from '@/helper/DateHelper';
import RoutingWeekPlanning from '@/class/RoutingWeekPlanning';
import moment from 'moment/moment';

class GroupedRouting extends BaseRouting {
  public readonly regroupId : number;

  // routingsInGroup's arg is used to not research all routings in group, and use the arg instead :)
  constructor(
    regroupId: number,
    affaire: Affaire,
    routingsInGroup: undefined | Array<RankRouting> = undefined,
  ) {
    super();
    this.regroupId = regroupId;
    this.Affaire = affaire;
    this.initManuTime(routingsInGroup);
  }

  fillFromJSON(data: any) {
    super.fillFromJSON(data);
    if (data.weeksPlanning !== undefined) {
      data.weeksPlanning.forEach((dataWp: any) => {
        const wr = new RoutingWeekPlanning(this, true);
        wr.fillFromJSON(dataWp);
      });
    }
  }

  persistDate() {
    super.persistDate(this.regroupId);
  }

  public getGroup(): string {
    return Object.keys(CostCenter.regroupedGroupId)[Object.values(CostCenter.regroupedGroupId)
      .indexOf(this.regroupId)];
  }

  private initManuTime(routingsInGroupArg: undefined | Array<RankRouting> = undefined) {
    if (this.Affaire === undefined) return;

    let routingsInGroup: Array<RankRouting>;
    if (routingsInGroupArg === undefined) {
      routingsInGroup = CostCenterHelper.getRoutingInJobByGroup(this.Affaire, this.getGroup());
    } else {
      routingsInGroup = routingsInGroupArg;
    }
    if (routingsInGroup.length === 0) return;

    this.ManuTime = routingsInGroup
      .map((r) => r.getManuTimeWithCoef())
      .reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      );
  }

  public moveDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
    checkRoutingOnTop: boolean,
  ): number {
    const diffBetweenStartAndEnd = DateHelper.getBusinessDatesCount(
      this.getStartDate(),
      this.getEndDate(),
    );
    const newStartDate = DateHelper
      .addDaysWithoutWeekEnd(this.getStartDate(), dayMove);
    const newEndDate = DateHelper
      .addDaysWithoutWeekEnd(newStartDate, diffBetweenStartAndEnd - 1);

    const newStartDateTest = new Date(newStartDate);
    newStartDateTest.setHours(0, 0, 0, 0);

    const newEndDateTest = new Date(newEndDate);
    newEndDateTest.setHours(0, 0, 0, 0);

    const maxDateTest = new Date(maxDate);
    maxDateTest.setHours(0, 0, 0, 0);

    const minDateTest = new Date(minDate);
    minDateTest.setHours(0, 0, 0, 0);

    if (newEndDateTest > maxDateTest && dayMove > 0) {
      return 0;
    }
    if (newStartDateTest < minDateTest && dayMove < 0) {
      return 0;
    }

    if (snapOnWeek) {
      this.startDate = DateHelper.setToMonday(newStartDate);
    } else {
      this.startDate = newStartDate;
    }

    if (snapOnWeek) {
      this.endDate = DateHelper.setToFriday(newEndDate);
    } else {
      this.endDate = newEndDate;
    }

    const rr = this.Affaire?.regroupedRoutings;
    if (rr === undefined) return dayMove;
    if (index + 1 >= rr.length) {
      return dayMove;
    }
    rr[index + 1].handleRouting(
      minDate,
      maxDate,
      routings,
      dayMove,
      index + 1,
      snapOnWeek,
      checkRoutingOnTop,
    );
    return 0;
  }

  public handleRouting(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek = false,
    checkRoutingOnTop = true,
  ) {
    this.moveDate(
      minDate,
      maxDate,
      routings,
      dayMove,
      index,
      snapOnWeek,
      checkRoutingOnTop,
    );
    // if (this.routingsWeekPlanning.length === 0) {
    //   this.moveDate(
    //     minDate,
    //     maxDate,
    //     routings,
    //     dayMove,
    //     index,
    //     snapOnWeek,
    //     checkRoutingOnTop,
    //   );
    //   return;
    // }
    //
    // let totalTimeDefine = 0;
    // let isStart = false;
    // let isAllFinished = true;
    // let maxStartDate = moment('1970-01-01');
    // let isPlannified = false;
    //
    // this.routingsWeekPlanning.forEach((weekPlanning) => {
    //   weekPlanning.routingsDayPlanning.forEach((dayPlanning) => {
    //     if (!dayPlanning.isNotFinishReplaned) {
    //       totalTimeDefine += dayPlanning.minutes;
    //     }
    //
    //     if (!dayPlanning.isFinished) {
    //       isAllFinished = false;
    //     }
    //
    //     if (dayPlanning.totalTimeClocking > 0 || dayPlanning.isFinished) {
    //       isStart = true;
    //
    //       const dateStart = moment(dayPlanning.startDate);
    //
    //       if (dateStart.isAfter(maxStartDate)) {
    //         maxStartDate = dateStart;
    //       }
    //
    //       if (!dayPlanning.isFinished) {
    //         dayPlanning.setIsNotFinishReplaned(true);
    //       }
    //     } else if (dayPlanning.totalTimeClocking === 0) {
    //       const indexRouting = this.routingsWeekPlanning.indexOf(weekPlanning);
    //       this.routingsWeekPlanning.splice(indexRouting, 1);
    //       dayPlanning.deleteToApi();
    //       weekPlanning.remove();
    //       isPlannified = true;
    //     }
    //   });
    // });
    //
    // if ((totalTimeDefine < this.ManuTime) || !isAllFinished || isPlannified) {
    //   this.setIsReplanned(true);
    // }
    //
    // if (isStart) {
    //   if (dayMove > 0) {
    //     this.moveEndDate(
    //       maxStartDate.toDate(),
    //       maxDate,
    //       routings,
    //       dayMove,
    //       index,
    //       snapOnWeek,
    //     );
    //   }
    // } else {
    //   this.moveDate(
    //     minDate,
    //     maxStartDate.toDate(),
    //     routings,
    //     dayMove,
    //     index,
    //     snapOnWeek,
    //     checkRoutingOnTop,
    //   );
    // }
  }

  moveEndDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
  ): void {
    let newEndDate = DateHelper
      .addDaysWithoutWeekEnd(this.getEndDate(), dayMove);
    if (newEndDate < this.getStartDate()) {
      this.endDate = new Date(this.getStartDate());
      return;
    }

    newEndDate = new Date(DateHelper.max(DateHelper.min(newEndDate, maxDate), this.startDate));
    if (snapOnWeek) {
      this.endDate = DateHelper.setToFriday(newEndDate);
    } else {
      this.endDate = newEndDate;
    }

    this.recalculateWeeksHoursDistributor();

    const rr = this.Affaire?.regroupedRoutings;
    if (rr === undefined) return;
    if (index + 1 >= rr.length) {
      return;
    }

    // Move on bottom routings
    rr[index + 1].handleRouting(minDate, maxDate, routings, dayMove, index + 1, snapOnWeek, false);
  }

  moveStartDate(
    minDate: Date,
    maxDate: Date,
    routings: Array<BaseRouting>,
    dayMove: number,
    index: number,
    snapOnWeek: boolean,
  ): void {
    const newStartDate = DateHelper
      .addDaysWithoutWeekEnd(this.getStartDate(), dayMove);

    if (snapOnWeek) {
      this.startDate = DateHelper.setToMonday(
        DateHelper.min(DateHelper.max(newStartDate, minDate), this.endDate),
      );
    } else {
      this.startDate = DateHelper.min(DateHelper.max(newStartDate, minDate), this.endDate);
    }
    this.recalculateWeeksHoursDistributor();
  }

  getCostCenterName(): string {
    return this.getGroup();
  }
}

export default GroupedRouting;
